.sigin-container {
  height: 100vh;
}

.welcome-signin {
  color: var(--Text-h2, #171717);
  font-family: Outfit;
  font-size: 1rem;
  /* 16px */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: stretch;
}

.credentials-signin {
  color: #383838;
  font-family: Outfit;
  font-size: 0.8125rem;
  /* 13px */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0.75rem;
  /* 12px */
}

.google-signin-button {
  width: 22.1875rem;
  /* 355px */
  height: 2.75rem;
  /* 44px */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 0.375rem;
  /* 6px */
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  margin-top: 1.5rem;
  /* 24px */
}

.google-icon {
  width: 1.75rem;
  /* 28px */
  height: 1.75rem;
  /* 28px */
  flex-shrink: 0;
  margin-left: -5.625rem;
  /* -90px */
  margin-top: -0.3125rem;
  /* -5px */
}

.google-text {
  color: #3F3F46;
  /* var(--Neutral-1, #3F3F46) */
  font-family: 'Outfit', sans-serif;
  font-size: 0.875rem;
  /* 14px */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 2.5rem;
  /* 40px */
}

.or-style {
  display: flex;
  width: 21.75rem;
  /* 348px */
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  /* 12px */
  margin-top: 0.75rem;
  /* 12px */
}

.email-sigin {
  color: var(--Neutral_2, #52525B);
  font-family: Outfit;
  font-size: 0.9375rem;
  /* 15px */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.google-signin-btn {
  color: var(--Neutral-1, #3F3F46);
  font-family: Outfit, sans-serif;
  font-size: 0.875rem;
  /* 14px */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Email-sigin-box {
  width: 22.1875rem;
  /* 355px */
  display: flex;
  height: 2.8125rem;
  /* 45px */
  flex-shrink: 0;
  border-radius: 0.375rem;
  /* 6px */
  border: 1px solid var(--Stroke-black, rgba(0, 0, 0, 0.10));
}

.password-sigin-box {
  width: 22.1875rem;
  /* 355px */
  display: flex;
  height: 2.8125rem;
  /* 45px */
  flex-shrink: 0;
  border-radius: 0.375rem;
  /* 6px */
  border: 1px solid var(--Stroke-black, rgba(0, 0, 0, 0.10));
}

/* Show tooltip when hovering over the icon */
.relative:hover .tooltip {
  display: block;
  /* Tooltip will be shown when hovering */
}

/* Tooltip styling */
.tooltip {
  width: 11.875rem;
  /* 190px */
  height: 2.4875rem;
  /* 39.819px */
  flex-shrink: 0;
  fill: var(--Background-main, #FEF9F7);
  stroke-width: 1px;
  stroke: var(--black_stroke, rgba(0, 0, 0, 0.10));
  filter: drop-shadow(0.0625rem 0.125rem 0.25rem rgba(0, 0, 0, 0.10)) drop-shadow(0.125rem 0.5rem 0.5rem rgba(0, 0, 0, 0.09)) drop-shadow(0.3125rem 1.0625rem 0.6875rem rgba(0, 0, 0, 0.05)) drop-shadow(0.5625rem 1.9375rem 0.8125rem rgba(0, 0, 0, 0.01)) drop-shadow(0.9375rem 3rem 0.875rem rgba(0, 0, 0, 0.00));
  font-size: 0.75rem;
  /* 12px */
  line-height: 1.5;
  /* Ensure the text is spaced well */
  padding: 0.3125rem;
  /* 5px */
  text-align: center;
  /* Center the text */
  display: none;
  /* Hidden by default */
}


.Email-sigin-placeholder {
  color: rgba(115, 96, 123, 0.60);
  font-family: Outfit;
  font-size: 0.875rem;
  /* 14px */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 3.3125rem;
  /* 53px */
  margin-top: 0px;
  color: var(--Text-h2, #171717);
  border: none;
}

emailsigin-icon {
  width: 1.25rem;
  /* 20px */
  height: 1.25rem;
  /* 20px */
  flex-shrink: 0;
}

.password-sigin {
  color: var(--Neutral_2, #52525B);
  font-family: Outfit;
  font-size: 0.9375rem;
  /* 15px */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0.75rem;
  /* 12px */
}

.password-sigin-placeholder {
  color: rgba(115, 96, 123, 0.60);
  font-family: Outfit;
  font-size: 0.875rem;
  /* 14px */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  box-sizing: border-box;
  width: 60%;
  transition: all 0.3s ease;
  color: var(--Text-h2, #171717);
}

input[type="password"] {
  color: var(--Text-h2, #171717);
  font-family: Outfit;
  font-size: 0.875rem;
  /* 14px */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

input[type="text"] {
  color: var(--Text-h2, #171717);
  font-family: Outfit;
  font-size: 0.875rem;
  /* 14px */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Default styles for the placeholder */
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="text"]::placeholder {
  color: rgba(115, 96, 123, 0.60);
  /* Default placeholder color */
  font-size: 0.875rem;
  /* 14px */
  font-weight: 400;
  transition: none;
  /* Remove transition effect for the placeholder */
}

input[type="email"]:hover,
input[type="password"]:hover,
input[type="text"]:hover {
  transform: none;
  /* This removes any hover effect that zooms in */
}

/* Make sure the placeholder stays the same on focus */
input[type="email"]:focus::placeholder,
input[type="password"]:focus::placeholder,
input[type="text"]:focus::placeholder {
  opacity: 1;
  /* Ensure the placeholder remains visible even on focus */
  transform: none;
  /* Remove any scale or zoom effect */
  font-size: 0.875rem;
  /* 14px */
}

/* Remove any zoom effect when typing */
input[type="email"]:focus,
input[type="password"]:focus,
input[type="text"]:focus {
  outline: none;
  /* Optionally remove outline on focus */
}

input[type="email"]:focus,
input[type="password"]:focus,
input[type="text"]:focus {
  outline: none;
  transform: none;
  /* Remove any zooming effect */
  transition: none;
  /* Disable transitions */
}


/* Optional: Ensuring no scaling/zoom on hover */
input[type="email"]:hover,
input[type="password"]:hover,
input[type="text"]:hover {
  transform: none;
  /* Remove any hover effect that zooms in */
}

.popup-show-letter.email-error-hover {
  position: absolute;
  left: -4.375rem;
  /* -70px */
  top: 120%;
  /* You may want to adjust this based on your layout */
  padding: 0.125rem;
  /* 2px */
  padding-top: 0.4375rem;
  /* 7px */
  width: 10.625rem;
  /* 170px */
  height: 1.9262rem;
  /* 30.819px */
  background-color: #FEF9F7;
  border: 1px solid rgba(0, 0, 0, 0.10);
  border-radius: 0.375rem;
  /* 6px */
  font-family: 'Outfit', sans-serif;
  /* Assuming the Outfit font is imported in your project */
  font-size: 0.75rem;
  /* 12px */
  font-weight: 400;
  /* Normal weight */
  line-height: 1rem;
  /* 16px */
  text-align: center;
  color: #000000;
  box-shadow: 0.0625rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.10);
  /* Shadow equivalent */
}

.popup-show-letter {
  color: var(--Text-h1, #18101C);
  text-align: center;
  font-family: Outfit;
  font-size: 0.75rem;
  /* 12px */
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  /* 16px */
}

.remember-sigin {
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 0.8125rem;
  /* 13px */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.26px;
  margin-top: 1.125rem;
  /* 18px */
}

.forgot-pass-sigin {
  color: var(--Primari_300, #BB42AE);
  font-family: Outfit;
  font-size: 0.8125rem;
  /* 13px */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.26px;
  margin-top: 0.625rem;
  /* 10px */
}

.login-text-sigin {
  color: var(--Neutral_2, #52525B);
  text-align: center;
  font-family: Outfit;
  font-size: 0.9375rem;
  /* 15px */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: -2.5rem;
  /* -40px */
}

.login-click-singin {
  color: var(--Primary-500, #C95EBE);
  font-family: Outfit;
  font-size: 0.9375rem;
  /* 15px */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.signin-button {
  color: #FFF;
  font-family: Outfit;
  font-size: 1rem;
  /* 16px */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 3.5rem;
  /* 56px */
  margin-top: -0.3125rem;
  /* -5px */
}

.Email-sigin-placeholder:focus,
.password-sigin-placeholder:focus {
  color: var(--Text-h2, #171717);
  /* Text color when the user types */
  font-family: 'Outfit', sans-serif;
  font-size: 0.875rem;
  /* 14px */
  font-weight: 500;
  line-height: normal;
}

/* Style for the custom checkbox container */
.remember-me-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.remember-me-checkbox input[type="checkbox"] {
  /* Hide the default checkbox */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 1.25rem;
  /* 20px */
  height: 1.25rem;
  /* 20px */
  border-radius: 0.375rem;
  /* 6px */
  background: #D9D9D9;
  /* Default background color */
  border: 0.125rem solid #B0B0B0;
  /* Light border */
  position: relative;
  transition: border-color 0.3s ease;
}

/* When the checkbox is checked */
.remember-me-checkbox input[type="checkbox"]:checked {
  /* border-color: #171717; Dark border when checked */
}

/* Creating the black tick mark */
.remember-me-checkbox input[type="checkbox"]:checked::after {
  content: "\2713";
  /* Unicode character for checkmark (✓) */
  position: absolute;
  top: -0.1875rem;
  /* -3px */
  left: 0.25rem;
  /* 4px */
  bottom: 0.125rem;
  /* 2px */
  font-size: 1rem;
  /* 16px */
  font-weight: bold;
  /* Make the tick bold */
  font-family: 'Arial', sans-serif;
  /* Choose a font that supports bold tick */
  color: #171717;
  /* Black color for the tick */
  transform: none;
  /* Ensure no rotation or distortion */
}

/* Label styling */
.remember-sigin {
  margin-left: 0.625rem;
  /* 10px */
  font-size: 0.875rem;
  /* 14px */
  color: rgba(115, 96, 123, 0.60);
}

.logo-container {
  position: absolute;
  top: 4rem;
  left: 47%;
  transform: translateX(-50%);
  width: 200px;
  height: auto;
  z-index: 10;
  /* Ensure the logo stays above other elements */
  display: flex;
  /* Ensure the container respects flex properties */
  align-items: center;
  /* Center the image vertically */
  justify-content: center;
  /* Center the image horizontally */
}
@media (min-width: 200px) and (max-width: 300px) {
  .logo-container {
    
  }

  .logo-container {
    margin-left: 0px;
  }

  .welcome-signin{
    margin-left: 70px;
    margin-top: 4px;
  }
  .siginCont {
    width: 90%; /* Make it responsive */
    max-width: 320px;
    min-width: 250px; /* Ensures it doesn’t shrink too much */
    /* margin: 0 auto; */
    /* padding: 10px; */
    margin-left: 10px;
  }
  .google-signin-button{
    width: 245px;
    margin-left: -10px;
  }
  .line-sigin{
    width: 10px;
  }
  .or-style {
    display: flex;
    width: 15.5rem;
    align-items: center;
    justify-content: center;
    gap: 8px; /* Space between "or" and lines */
  }
  
  .or-style hr {
    flex-grow: 1;
    border: none;
    height: 0.5px; /* Decreased height */
    background-color: gray;
  }
  
  .or-style span {
    font-size: 14px;
    color: #6b7280; 
    white-space: nowrap;
  }
  .credentials-signin{
    margin-left: 2px;
    font-size: 0.7125rem
  }
  .Email-sigin-box {
    width: 15.5rem;
  }
  .password-sigin-box{
    width: 15.5rem;
  }
  .send-button-sigin{
    width: 15.5rem;
  }
  .login-text-sigin{
    margin-left: 7px;
  }
  .Email-sigin-placeholder{
    margin-left: 2.3rem;
  }
  .button-eye-email{
    margin-left: 1rem;
  }
}

@media (min-width: 300px) and (max-width: 375px) {
  .logo-container {
    
  }

  .logo-container {
    margin-left: 0px;
  }

  .welcome-signin{
    margin-left: 70px;
    margin-top: 4px;
  }
  .siginCont {
    width: 90%; /* Make it responsive */
    max-width: 320px;
    min-width: 250px; /* Ensures it doesn’t shrink too much */
    /* margin: 0 auto; */
    /* padding: 10px; */
    margin-left: 10px;
  }
  .google-signin-button{
    width: 265px;
    margin-left: -10px;
  }
  .line-sigin{
    width: 10px;
  }
  .or-style {
    display: flex;
    width: 15.5rem;
    align-items: center;
    justify-content: center;
    gap: 8px; /* Space between "or" and lines */
  }
  
  .or-style hr {
    flex-grow: 1;
    border: none;
    height: 0.5px; /* Decreased height */
    background-color: gray;
  }
  
  .or-style span {
    font-size: 14px;
    color: #6b7280; 
    white-space: nowrap;
  }
  .credentials-signin{
    margin-left: 2px;
    font-size: 0.7125rem
  }
  .Email-sigin-box {
    width: 15.5rem;
  }
  .password-sigin-box{
    width: 15.5rem;
  }
  .send-button-sigin{
    width: 15.5rem;
  }
  .login-text-sigin{
    margin-left: 7px;
  }
  .Email-sigin-placeholder{
    margin-left: 2.3rem;
  }
  .button-eye-email{
    margin-left: 1rem;
  }
}

@media (min-width: 375px) and (max-width: 812px) {
  .logo-container {
    
  }

  .logo-container {
    margin-left: 0px;
  }

  .welcome-signin{
    margin-left: 90px;
    margin-top: 4px;
  }
  .siginCont{
    width: 330px;
    margin-left: 2px;
  }
  .google-signin-button{
    width: 295px;
    margin-left: -10px;
  }
  .line-sigin{
    width: 10px;
  }
  .or-style {
    display: flex;
    width: 17.5rem;
    align-items: center;
    justify-content: center;
    gap: 8px; /* Space between "or" and lines */
  }
  
  .or-style hr {
    flex-grow: 1;
    border: none;
    height: 0.5px; /* Decreased height */
    background-color: gray;
  }
  
  .or-style span {
    font-size: 14px;
    color: #6b7280; 
    white-space: nowrap;
  }
  .credentials-signin{
    margin-left: 10px;
  }
  .Email-sigin-box {
    width: 18rem;
  }
  .password-sigin-box{
    width: 18rem;
  }
  .send-button-sigin{
    width: 18rem;
  }
  .login-text-sigin{
    margin-left: 2rem;
  }
  .Email-sigin-placeholder{
    margin-left: 2.3rem;
  }
  .button-eye-email{
    margin-left: 1rem;
  }
}
@media (max-width: 768px) {
  .Email-sigin-placeholder {
    margin-left: 1rem !important; /* Reduce margin for small screens */
    width: 100%; /* Ensure placeholder text stays within input */
    box-sizing: border-box;
  }

  .Email-sigin-box input {
    padding-left: 2.5rem; /* Adjust based on icon size */
    width: 85.3%;
    box-sizing: border-box;
  }
}


@media (min-width: 430px) and (max-width: 932px) {
 

  .logo-container {
    margin-left: 0px;
  }

  .welcome-signin{
    margin-left: 115px;
    margin-top: 4px;
  }
  .siginCont{
    width: 380px;
    height: 518px;
    margin-left: 2px;
  }
  .google-signin-button{
    width: 345px;
    margin-left: -10px;
  }
  .line-sigin{
    width: 10px;
  }
  .or-style {
    display: flex;
    width: 20.5rem;
    align-items: center;
    justify-content: center;
    gap: 8px; 
    margin-top: 15px;
  }
  
  .or-style hr {
    flex-grow: 1;
    border: none;
    height: 0.5px; 
    margin-top: 15px;
    background-color: gray;
  }
  
  .or-style span {
    font-size: 14px;
    color: #6b7280; 
    white-space: nowrap;
    margin-top: 15px;
  }
  .credentials-signin{
    margin-left: 35px;
    margin-top: 15px;
  }
  .Email-sigin-box {
    width: 21rem;
    margin-top: 10px;
  }
  .password-sigin-box{
    width: 21rem;
    margin-top: 10px;
  }
  .send-button-sigin{
    width: 21rem;
    margin-top: 10px;
  }
  .login-text-sigin{
    margin-left: 7px;
  }
  .Email-sigin-placeholder{
    margin-left: 2.3rem;
  }
  .button-eye-email{
    margin-left: 1rem;
  }
}
@media (min-width: 744px) and (max-width: 1133px) {
 

  .logo-container {
    margin-left: 0px;
  }

  .welcome-signin{
    margin-left: 115px;
    margin-top: 4px;
  }
  .siginCont{
    width: 380px;
    height: 518px;
    margin-left: 2px;
  }
  .google-signin-button{
    width: 345px;
    margin-left: -10px;
  }
  .line-sigin{
    width: 10px;
  }
  .or-style {
    display: flex;
    width: 20.5rem;
    align-items: center;
    justify-content: center;
    gap: 8px; 
    margin-top: 15px;
  }
  
  .or-style hr {
    flex-grow: 1;
    border: none;
    height: 0.5px; 
    margin-top: 15px;
    background-color: gray;
  }
  
  .or-style span {
    font-size: 14px;
    color: #6b7280; 
    white-space: nowrap;
    margin-top: 15px;
  }
  .credentials-signin{
    margin-left: 35px;
    margin-top: 15px;
  }
  .Email-sigin-box {
    width: 21rem;
    margin-top: 10px;
  }
  .password-sigin-box{
    width: 21rem;
    margin-top: 10px;
  }
  .send-button-sigin{
    width: 21rem;
    margin-top: 10px;
  }
  .login-text-sigin{
    margin-left: 7px;
  }
  .Email-sigin-placeholder{
    margin-left: 2.3rem;
  }
  .button-eye-email{
    margin-left: 1rem;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .logo-container {
    left: 38vw;
    /* Custom left value for this range */
    transform: none;
    /* Remove translateX for specific ranges */
  }
}

@media (min-width: 1280px) and (max-width: 1440px) {
  .logo-container {
    left: 47vw;
  }
}

@media (min-width: 1440px) and (max-width: 1536px) {
  .logo-container {
    left: 47vw;
  }
}

@media (min-width: 1536px) and (max-width: 1920px) {
  .logo-container {
    left: 48vw;
  }
}

@media (min-width: 1920px) and (max-width: 2560px) {
  .logo-container {
    left: 48vw;
  }
}
