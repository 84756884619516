.signup-container{
    height: 100vh;
}
.policy{
    color: var(--Neutral_2, #52525B);
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.26px;
}
.termsandCondition{
    color: #71A4F0;
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.26px;
}
.Email-signup-placeholder{
    color: var(--Text-h2, #171717);
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.logo-container {
    position: absolute; /* Ensures relative positioning */
    top: 4rem; /* Keeps the logo vertically aligned */
    left: 50%; /* Centers the logo horizontally */
    transform: translateX(-50%); /* Adjusts for centering based on its width */
    width: 200px; /* Define a specific width */
    height: auto; /* Maintain aspect ratio */
    z-index: 10; /* Ensure the logo stays above other elements */
    display: flex; /* Ensure the container respects flex properties */
    align-items: center; /* Center the image vertically */
    justify-content: center; /* Center the image horizontally */
  }
  
  @media (min-width: 1024px) and (max-width: 1280px) {
    .logo-container {
      left: 38vw; /* Custom left value for this range */
      transform: none; /* Remove translateX for specific ranges */
    }
  }
  
  @media (min-width: 1280px) and (max-width: 1440px) {
    .logo-container {
      left: 47vw;
    }
  }
  
  @media (min-width: 1440px) and (max-width: 1536px) {
    .logo-container {
      left: 47vw;
    }
  }
  
  @media (min-width: 1536px) and (max-width: 1920px) {
    .logo-container {
      left: 48vw;
    }
  }
  
  @media (min-width: 1920px) and (max-width: 2560px) {
    .logo-container {
      left: 48vw;
    }
  }

  @media (min-width: 375px) and (max-width: 812px) {
  
  }
  
  @media (min-width: 430px) and (max-width: 932px) {
 

    .logo-container {
      margin-left: 0px;
    }
  
    .welcome-signin{
      margin-left: 105px;
      margin-top: 4px;
    }
    .siginCont{
      width: 380px;
      height: 618px;
      margin-left: 2px;
    }
    .google-signin-button{
      width: 345px;
      margin-left: -10px;
    }
    .line-sigin{
      width: 10px;
    }
    .or-style {
      display: flex;
      width: 20.5rem;
      align-items: center;
      justify-content: center;
      gap: 8px; 
      margin-top: 15px;
    }
    
    .or-style hr {
      flex-grow: 1;
      border: none;
      height: 0.5px; 
      margin-top: 15px;
      background-color: gray;
    }
    
    .or-style span {
      font-size: 14px;
      color: #6b7280; 
      white-space: nowrap;
      margin-top: 15px;
    }
    .credentials-signin{
      margin-left: 35px;
      margin-top: 15px;
    }
    .Email-sigin-box {
      width: 21rem;
      margin-top: 10px;
    }
    .password-sigin-box{
      width: 21rem;
      margin-top: 10px;
    }
    .send-button-sigin{
      width: 21rem;
      margin-top: 10px;
    }
    .login-text-sigin{
      margin-left: 7px;
    }
  }

  @media (max-width: 768px) {
    /* Fix Full Name Placeholder */
    .Email-sigin-box input {
      padding-left: 2.5rem !important; /* Adjust left padding for input text */
      width: 80%;
      box-sizing: border-box;
    }
  
    .Email-signup-placeholder {
      margin-left: 1rem !important; /* Reduce margin for mobile */
      width: 100%;
      box-sizing: border-box;
    }
    .eye-icon-signup{
      margin-left: 20px;
    }
    .password-sigin-placeholder{
      margin-left: -10px;
    }
  
    
  }
  
