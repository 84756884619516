.full-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: "Outfit", sans-serif;
}

.header-container {
  width: 100%;
  max-width: 98.875rem;
  margin: 0 auto;
  padding-bottom: 1.25rem;
  color: #000;
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.contract-table-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.Input-space {
  color: var(--Neutral-150, #71717A);
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.contract-table-header {
  display: flex;
  font-family: Outfit;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: normal;
}
.action-icons-contract {
  display: flex;
  gap: 8px;
}

.icon-button-contract {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
}

.icon-button-contract img {
  width: 36px;
  height: 36px;
}


.search-filter-container {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.search-box {
  width: 22.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #FFF;
  display: inline-flex;
  padding: 0.5rem 7.0625rem 0.5rem 1.25rem;
  align-items: center;
  gap: 0.75rem;
  margin-top: 2.0625rem;
  margin-left: 12.5rem;
}

.search-box input {
  width: 11.9375rem;
  height: 1.125rem;
  color: var(--Neutral-150, #71717A);
  font-family: Outfit;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: normal;
  margin-left: 0.3125rem;
  border: none;
  outline: none;
}

.Input-space {
  color: var(--Neutral-150, #71717A);
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.filter-button {
  width: 8.9375rem;
  height: 3.6875rem;
  background: transparent;
  border: none;
  margin-top: 2.0625rem;
  cursor: pointer;
  pointer-events: auto;
}

.filter-button:hover {
  background: transparent !important; 
  color: inherit !important; 
  border: none !important;
  cursor: pointer !important;
}


.upload-button {
  width: 172px;
  height: 40px;
  display: inline-flex;
padding: 8px 16px;
justify-content: center;
align-items: center;
gap: 8px;
  border-radius: 0.375rem;
  border: 1px solid var(--Primary-500, #C95EBE);
  color: var(--Primary-500, #C95EBE);
  cursor: pointer;
  margin-left: 39.8125rem;
  margin-top: 2.6625rem;
}
.upload-button-invoice {
  width: 172px;
  height: 40px;
  display: inline-flex;
padding: 8px 16px;
justify-content: center;
align-items: center;
gap: 8px;
  border-radius: 0.375rem;
  border: 1px solid var(--Primary-500, #C95EBE);
  color: var(--Primary-500, #C95EBE);
  cursor: pointer;
  margin-left: 39.8125rem;
  margin-top: 2.6625rem;
}
.upload-button-spreadsheets {
  width: 172px;
  height: 40px;
  display: inline-flex;
padding: 8px 16px;
justify-content: center;
align-items: center;
gap: 8px;
  border-radius: 0.375rem;
  border: 1px solid var(--Primary-500, #C95EBE);
  color: var(--Primary-500, #C95EBE);
  cursor: pointer;
  margin-left: 39.8125rem;
  margin-top: 2.6625rem;
}

.upload-icon {
  width: 16px; 
  height: 16px; 
  display: block;
}

.upload-text {
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  white-space: nowrap;
  letter-spacing: 0.01rem;
  color: var(--Primary-500, #C95EBE);
}

.custom-checkbox {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.375rem;
  border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.1));
  appearance: none;
  cursor: pointer;
  position: relative;
}

.custom-checkbox:checked::after {
  content: "✓";
  color: "#52525B";
  font-size: 1.125rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container {
  border-radius: 0.375rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #FFF;
  margin-top: 0.4375rem;
  margin-right: 6.25rem;
  flex-grow: 1;
  width: 100%;
  margin-left: 12.5rem;
  max-height: calc(100vh - 12.5rem);
  box-sizing: border-box;
  padding-right: 0.625rem;
  overflow-y: auto;
  overflow-x: auto;
}

.inner-wrapper {
  padding-right: 0.625rem;
  box-sizing: border-box;
}

.container::-webkit-scrollbar {
  width: 0.5625rem;
  flex-shrink: 0;
  border-radius: 1.125rem;
}

.container::-webkit-scrollbar-track {
  border-radius: 1.125rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #DBDBDB;
  margin-right: 0.625rem;
  margin-top: 3.75rem;
  margin-bottom: 3.125rem;
}

.container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.625rem;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #FAF9F6;
}
.full-container, .header-container {
  max-width: none;
}


.contract-table {
  width: 100%;
  border-collapse: collapse;
}

.contract-table thead {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  color: var(--text_non_active, #73607B);
  line-height: normal;
}
.filter-button.filter-applied {
  background-color: #b793ed; /* Highlighted color when filter is applied */
  border: 2px solid #7a52a1;
}
.contract-table td {
  max-width: 180px;
  padding: 0.59rem 0.625rem;
  text-align: left;
  color: var(--Text-h2, #171717);
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.contract-table th {
  padding: 0.75rem;
  text-align: left;
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.action-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  border-radius: 0.75rem 0rem 0.75rem 0.75rem;
  border: 1px solid var(--Stroke-black, rgba(0, 0, 0, 0.1));
  background: #FFF;
  padding: 0.625rem;
  width: 12.375rem;
  height: 6.5rem;
  z-index: 1000;
  margin-left: 1.5625rem;
  margin-top: -0.625rem;
  transform: translate(-110%, 0);
}

.parent-container {
  position: relative;
}

.action-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 0.875rem;
  margin-left: 1.125rem;
  color: var(--Neutral-150, #71717A);
  font-family: Outfit;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #666;
  row-gap: 1.25rem;
}

.action-item:hover {
  background-color: #f0f0f0;
}

.action-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.contract-table td.contract-link {
  color: #9a6fdf;
  cursor: pointer;
  text-decoration: underline;
}

.pagination {
  position: absolute;
  bottom: 34px;
  left: 201; 
  width: 81.8vw; 
  background-color: #fff; 
  display: flex;
  align-items: center;
  padding: 10px 0;
  padding-left: 20px;
  border-radius: 6px;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1); 
  z-index: 10; 
}



.pagination button {
  display: flex;
  width: 1rem;
  height: 1rem;
  transform: rotate(-180deg);
  padding: 0.3333125rem 0.2083125rem;
  justify-content: center;
  align-items: center;
  margin-left: 0.625rem;
}

.contentDown {
  color: var(--One-Liquidity-Text-Black-50, #666);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 0.625rem;
}

.page-selector {
  color: var(--Black-30, #8B8D97);
  font-family: Outfit;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  height: 1.4375rem;
  padding: 0.25rem 0.375rem;
  padding-left: 0.3125rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background: rgba(94, 99, 102, 0.08);
}
.lowerPart {
  margin-left: 1.875rem;
  border: none;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.lt,
.gt {
  border: none !important;
  background: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.popup-content {
  background: white;
  padding: 1.25rem;
  border-radius: 0.5rem;
  width: 18.75rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  text-align: center;
}

.popup-content h2 {
  margin-bottom: 0.9375rem;
}

.popup-content input[type="file"] {
  margin-bottom: 0.9375rem;
}

.popup-content button {
  margin: 0.3125rem;
  padding: 0.625rem 1.25rem;
  border: none;
  border-radius: 0.3125rem;
  cursor: pointer;
}

.popup-content button[disabled] {
  background-color: #ddd;
  cursor: not-allowed;
}

input::placeholder {
  font-weight: normal;
}

.inline-edit-input {
  width: 100%;
  height: 100%;
  font-size: inherit;
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0;
  box-sizing: border-box;
}

.edit-contract {
  padding-left: 5px;
  width: 8.5rem;
  height: 2.0625rem;
  flex-shrink: 0;
  border-radius: 0.375rem;
  border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.1));
}
.edit-contract-custom-one {
  padding-left: 5px;
  width: 12.5rem;
  height: 2.0625rem;
  flex-shrink: 0;
  border-radius: 0.375rem;
  border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.1));
}
.edit-contract-custom-two {
  padding-left: 5px;
  width: 9.5rem;
  height: 2.0625rem;
  flex-shrink: 0;
  border-radius: 0.375rem;
  border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.1));
}
.button-cat {
  position: absolute;
  right: 1.875rem;
  top: 2.1875rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.25rem;
}

.button-cat button {
  display: flex;
  padding: 0.5rem 0.875rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  color: var(--Text-h1, #18101C);
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #333;
  border-radius: 1.75rem;
  border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.1));
  background: var(--sec_shade, rgba(139, 85, 221, 0.16));
  cursor: pointer;
  transition: background-color 0.2s;
}

.button-cat button img {
  width: 1rem;
  height: 1rem;
  color: var(--Secc, #8B55DD);
}

.button-cat button:nth-child(1) {
  color: var(--Secc, #8B55DD);
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.button-cat button:nth-child(2) {
  color: rgba(226, 25, 25, 0.76);
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.button-cat button:nth-child(3) {
  color: var(--Text-h1, #18101C);
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.button-cat button:hover {
  background-color: #f0f0f0;
}

.cancel-bulk {
  display: flex !important;
  padding: 0.5rem !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 0.25rem !important;
  border-radius: 1.75rem;
  border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.1));
  background: #ECECEC;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-box {
  width: 25rem;
  background: white;
  padding: 1.875rem;
  border-radius: 0.75rem;
  text-align: center;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  margin-left: 6.25rem;
}

.popup-box h2 {
  color: var(--Neutral-500, #18181B);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.625rem;
}

.popup-box p {
  color: var(--Neutral-150, #71717A);
  text-align: center;
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1.25rem;
}
.popup-close-button-confirm{
  display: flex;
  padding: 0.5rem 2.25rem;
  justify-content: center;
  align-items: center;
  column-gap: 1.625rem;
  border: 1px solid black;
  border-radius: 0.5rem;
  background: var(--Primary-500, #C95EBE);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  margin-left: 0.3125rem;
  margin-top: 20px;
}
.popup-close-button-delete{
  display: flex;
  padding: 0.5rem 2.25rem;
  justify-content: center;
  align-items: center;
  column-gap: 1.625rem;
  border: 1px solid black;
  border-radius: 0.5rem;
  background: var(--Primary-500, #C95EBE);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  margin-left: 3.3125rem;
  margin-top: 20px;
}
.popup-close-button {
  display: flex;
  padding: 0.5rem 2.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border: 1px solid black;
  border-radius: 0.5rem;
  background: var(--Primary-500, #C95EBE);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  margin-left: 7.3125rem;
}

.popup-box-delete {
  width: 37.5rem;
  height: 27vh;
  background: white;
  padding: 1.875rem;
  border-radius: 0.75rem;
  text-align: center;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  margin-left: 6.25rem;
}

.popup-box-delete h2 {
  color: var(--Neutral-500, #18181B);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.popup-box-delete p {
  color: var(--Neutral-150, #71717A);
  text-align: center;
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 1.25rem;
}

popup-close-button-delete {
  display: flex;
  padding: 1.125rem 2.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border: 1px solid black;
  border-radius: 0.5rem;
  background: var(--Primary-500, #C95EBE);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  margin-left: 7.3125rem;
}

.save_Design {
  display: flex;
  padding: 0.5rem 2.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background: var(--Primary-500, #C95EBE);
}

.scrollable {
  max-height: 18.75rem;
  overflow-y: auto;
  scrollbar-width: thin;
}

.scrollable::-webkit-scrollbar {
  width: 0.5rem;
}

.scrollable::-webkit-scrollbar-thumb {
  background: #b793ed;
  border-radius: 0.625rem;
}
.no-contracts-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
}


.no-contracts-icon img {
  max-width: 100px; 
  opacity: 0.8;
}

.no-contracts-para {
  color: var(--text_non_active, #73607B);
  text-align: center;
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px; 
  }
  @media (min-width: 1024px) and (max-width: 1280px) {
    body{
      background-color: #FEF9F7;
    }
    .search-box {
        width: 40%; 
        margin-left: 13rem; 
    }
  
   .container {
    width: calc(100% - 244px); 
    margin-left: 210px;
    max-width: none; 
    box-sizing: border-box; 
}

.pagination {
  width: calc(100% - 248px);
  background-color: #fff;
  padding: 10px 0;
  border-radius: 6px;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  font-size: 0.875rem;
}

.upload-button {
  position: fixed;
  top: 4.3rem;
  right: 2rem;
  z-index: 1000;
  max-width: 150px;
  width: auto;
  font-size: 1rem;
  background-color: #FFF;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}


.upload-container {
  position: relative;
  display: inline-block;
}

.hello-attach-tip{
  color: var(--Text-h2, #171717);
  font-family: Outfit;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 119px;
  height: 27px;
  flex-shrink: 0;
}

.upload-button {
  position: fixed;
  top: 4.3rem;
  right: 2rem;
  z-index: 1000;
  max-width: 150px;
  width: auto;
  font-size: 1rem;
  background-color: #FFF;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.upload-button-spreadsheets {
  position: fixed;
  top: 4.3rem;
  right: 2rem;
  z-index: 1000;
  max-width: 150px;
  width: auto;
  font-size: 1rem;
  background-color: #FFF;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}


  .upload-text{
    font-size: 0.8rem;
  }
  .contract-table td{
    font-size: 0.7rem;
  }
  .contract-table th{
    font-size: 0.7rem;
  }
  
  }
  
  @media (min-width: 1280px) and (max-width: 1440px) {
   
   
    .search-box {
      width: 40%;
      margin-left: 13rem;
    }
    .container {
      width: calc(100% - 244px);
      margin-left: 210px;
      max-width: none;
      box-sizing: border-box;
  }
  
  .pagination {
      width: calc(100% - 248px);
      background-color: #fff;
      padding: 10px 0;
      border-radius: 6px;
      box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
      font-size: 0.875rem;
  }
  
    .upload-button {
      margin-left: 10rem;
    }
    .upload-button-invoice {
      margin-left: 10rem;
    }
    .upload-button-spreadsheets {
      margin-left: 10rem;
    }
    .upload-text{
      font-size: 0.85rem;
    }
    .contract-table td{
      font-size: 0.8rem;
    }
    .contract-table th{
      font-size: 0.8rem;
    }
  }
  @media (min-width: 1280px) and (max-width: 1440px) {
    .search-box {
        width: 40%;
        margin-left: 13rem;
    }
    .container {
      width: calc(100% - 244px);
      margin-left: 210px;
      max-width: none;
      box-sizing: border-box;
  }
  
  .pagination {
      width: calc(100% - 248px);
      background-color: #fff;
      padding: 10px 0;
      border-radius: 6px;
      box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
      font-size: 0.875rem;
  }
  
  .upload-button {
      position: absolute;
      top: 0.3rem;
      right: 2.2rem;
      margin-left: 0;
  }
  .upload-button-invoice {
    position: absolute;
    top: 0.3rem;
    right: 2.2rem;
    margin-left: 0;
}
.upload-button-spreadsheets {
  position: absolute;
  top: 0.3rem;
  right: 2.2rem;
  margin-left: 0;
  width: 200px;
}
  
}
@media (min-width: 1440px) and (max-width: 1536px) {
  .search-box {
      width: 40%;
      margin-left: 13rem;
  }

  .container {
    width: calc(100% - 244px); 
    margin-left: 210px; 
    max-width: none; 
    box-sizing: border-box;
  }

  .pagination {
    width: calc(100% - 248px);
    background-color: #fff;
    padding: 10px 0;
    border-radius: 6px;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
    font-size: 0.875rem;
}

.upload-button {
    position: absolute;
    top: 0.3rem;
    right: 2.2rem;
    margin-left: 0;
}
.upload-button-invoice {
  position: absolute;
  top: 0.3rem;
  right: 2.2rem;
  margin-left: 0;
}
.upload-button-spreadsheets {
  position: absolute;
  top: 0.3rem;
  right: 2.2rem;
  margin-left: 0;
}


  .upload-text{
    font-size: 0.8rem;
  }
  .contract-table td{
    font-size: 0.9rem;
  }
  .contract-table th{
    font-size: 0.9rem;
  }
}
@media (min-width: 1536px) and (max-width: 1920px) {
  .search-box {
      width: 40%;
      margin-left: 13rem;
  }

  .container {
    width: calc(100% - 244px);
    margin-left: 210px;
    max-width: none;
    box-sizing: border-box;
}

.pagination {
    width: calc(100% - 248px);
    background-color: #fff;
    padding: 10px 0;
    border-radius: 6px;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
    font-size: 0.875rem;
}


  .upload-button {
      position: absolute;
      top: 0.3rem; 
      right: 2.2rem; 
      margin-left: 0; 
  }
  .upload-button-invoice {
    position: absolute;
    top: 0.3rem; 
    right: 2.2rem; 
    margin-left: 0; 
}
.upload-button-spreadsheets {
  position: absolute;
  top: 0.3rem; 
  right: 2.2rem; 
  margin-left: 0; 
  width: 200px;
}
  
 
}
@media (min-width: 1920px) and (max-width: 2560px) {
  .search-box {
      width: 60%;
      margin-left: 13rem;
  }

  .container {
    width: calc(100% - 244px);
    margin-left: 210px;
    max-width: none;
    box-sizing: border-box;
  }

  .pagination {
    width: calc(100% - 248px);
    background-color: #fff;
    padding: 10px 0;
    border-radius: 6px;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
    font-size: 0.875rem;
  }

  .upload-button {
      position: absolute;
      top: 0.3rem;
      right: 2.2rem;
      margin-left: 0;
      font-size: 1.125rem;
  }
  .upload-button-invoice {
    position: absolute;
    top: 0.3rem;
    right: 2.2rem;
    margin-left: 0;
    font-size: 1.125rem;
    width: 200px;
}
.upload-button-spreadsheets{
  position: absolute;
  top: 0.3rem;
  right: 2.2rem;
  margin-left: 0;
  font-size: 1.125rem;
  width: 240px;
}
}
