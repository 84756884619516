.account-profile {
    width: 1078px;
    height: 681px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
    background: #FEFDFD;
}

/* Default Active Account Button */
.account-sidebar-option {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    background: rgba(139, 85, 221, 0.16);
    color: var(--Secc, #8B55DD);
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
    margin-left: 2.5rem;
    border: 1px solid rgba(139, 85, 221, 0.5);
    /* Default border */
}

/* Inactive Account Button (No Border) */
.account-sidebar-option.inactive {
    background: transparent;
    color: rgba(0, 0, 0, 0.5);
    border: none !important;
    /* Ensure border is removed */
}

/* Default Delete Account Button */
.delete-sidebar-option {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    color: rgba(226, 25, 25, 0.75);
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    margin-left: 2.5rem;
    white-space: nowrap;
}

/* Active Delete Account Button */
.delete-sidebar-option.active {
    background: rgba(226, 25, 25, 0.20);
}


.sidebar-divider-account {
    width: 1px;
    height: 85vh;
    /* Ensure this matches the container height */
    background: var(--black_stroke, rgba(0, 0, 0, 0.10));
    margin: 0 32px;
    flex-shrink: 0;
    /* Prevent it from collapsing */
}




.account-box-area-profile {
    width: 81vw;
    height: 85vh;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    margin-top: 28px;
    margin-left: 210px;
}

.personal-info-account {
    color: var(--Neutral-1, #3F3F46);
    font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 40px;
    margin-left: 10px;
}

.account-profile-border-box {
    display: flex;
    justify-content: space-between;
    width: 807px;
    height: 100px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 0.5px solid rgba(0, 0, 0, 0.10);
    background: var(--Background-main, #FEF9F7);
    margin-left: 10px;
    margin-top: 28px;
}

.profile-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* Aligns both "Hii" to the right */
    flex-grow: 1;
    /* Takes up remaining space */
    text-align: right;
}



.email-container {
    margin-left: 290px;
}


/* Account Profile Container */
.account-profile-section {
    width: 807px;
    height: 101px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #fff;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 10px;
    margin-top: 28px;
}
.account-profile-section-another {
    display: flex;
    width: 807px;
    height: 90px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #fff;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 10px;
    margin-top: 28px;
}
.account-password-reset-heading{
    align-self: stretch;
    color: var(--Text-h1, #18101C);
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-left: 20px;
}
.account-password-reset-heading2{
    align-self: stretch;
    color: rgba(82, 82, 91, 0.80);
font-family: Outfit;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-left: 20px;
margin-top: 12px;
}

/* Header Row (Labels) */
.account-profile-header {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.account-profile-label {
    font-size: 14px;
    font-weight: 600;
    color: #808080;
    
}
.account-profile-label-email{
    font-size: 14px;
    font-weight: 600;
    color: #808080;
    margin-left: 65px;
}

/* Profile Data Row */
.account-profile-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    width: 100%;
    position: relative;
}

.account-profile-value {
    color: var(--Text-h1, #18101C);
    font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  margin-left: 147px;
  flex: 1;
  min-width: 200px; /* Ensures text and input stay the same width */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.account-profile-value-fullname{
    width: 250px; /* Fixed width for consistency */
    min-width: 250px;
    padding-top: 5px;
    padding-left: 10px;
    margin-left: -10px;
    width: 250px;
    height: 32px;
    color: var(--Text-h1, #18101C);
    font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.20);
 
}

.main-content.blurred {
  filter: blur(6px);
  pointer-events: none;
  user-select: none;
  transition: filter 0.3s ease;
}

.confetti-icon{
  width: 74.535px;
height: 86.266px;
flex-shrink: 0;
margin-left: 230px;
margin-top: -10px;
}
.celebration-left {
  left: 10;
}

.celebration-right {
  right: 0;
}
/* Edit Button */
.account-profile-edit-btn {
    display: flex;
    align-items: center;
    gap: 5px;
    border: none;
    background: #f5f5f5;
    padding: 6px 12px;
    border-radius: 8px;

    color: #000;
    cursor: pointer;
    display: inline-flex;
    padding: 8px 12px;
    align-items: center;
    gap: 6px;
    color: var(--Neutral_2, #52525B);
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -75px;
}

.account-profile-edit-btn img {
    width: 14px;
    height: 14px;
}


.circle-account-profile {
    width: 60px;
    height: 60px;
    background-color: #C95EBE;
    border-radius: 50%;
    display: inline-block;
    margin-left: 20px;

    position: relative;
}

.account-name-profile {
    color: #FFF;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 22px;
    margin-top: 16px;
    display: block;
}

.full-name-underborder-account h {
    color: var(--Text-h1, #18101C);
    font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 278px;

}

.account-profile-border-box {
    display: flex;
    align-items: center;
    /* Aligns items vertically */
    justify-content: flex-start;
    /* Ensures left alignment */
    width: 807px;
    height: 100px;
    border-radius: 16px;
    border: 0.5px solid rgba(0, 0, 0, 0.10);
    background: var(--Background-main, #FEF9F7);
    padding: 20px;
    gap: 16px;
    /* Adds spacing between the profile image and text */
}

/* Profile Circle */
/* .circle-account-profile {
    width: 60px;
    height: 60px;
    background-color: #C95EBE;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
} */

/* Name & Email Container */
.profile-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Centers text vertically */
    align-items: flex-start;
    /* Aligns text to the left */
    flex-grow: 1;
    /* Allows it to take the remaining space */
}

/* Full Name */
.full-name-text {
    font-family: Outfit;
    font-size: 16px;
    font-weight: 600;
    color: #3F3F46;
    margin: 0;
    /* Removes any default margins */
}

/* Email */
.email-text {
    font-family: Outfit;
    font-size: 14px;
    color: #545454;
    margin: 2px 0 0;
    /* Adjusts spacing between name and email */
}

.email-underborder-account {
    color: var(--Neutral-1, #3F3F46);
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.edit-icon {

    width: 24px;
    height: 24px;
    background-color: #FFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.edit-icon-account {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 10;
    display: flex;
    width: 21px;
    height: 21px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 26px;
    border: 0.5px solid rgba(63, 63, 70, 0.12);
    background: var(--White, #F5F4F3);
    box-shadow: 8px 13px 4px 0px rgba(0, 0, 0, 0.00), 5px 8px 4px 0px rgba(0, 0, 0, 0.01), 3px 5px 3px 0px rgba(0, 0, 0, 0.05), 1px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
}

/* Container to keep full name and edit icon in the same row */
.profile-edit-container {
    display: flex;
    align-items: center;
    gap: 10px; /* Spacing between name and edit icon */
}

/* Styling for the edit icon + text */
.edit-icon-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 6px;
    position: absolute;
    right: -5px;
    top:-40px;
    padding: 4px 8px;
    border-radius: 6px;
    background: #f5f5f5; /* Light background */
    transition: 0.3s ease-in-out;
}

.edit-icon-container:hover {
    background: #e0e0e0; /* Slight dark on hover */
}

/* Adjust icon size */
.edit-icon-account-fullname {
    width: 14px; /* Adjust size as needed */
    height: 14px;
}

/* Edit text style */
.edit-text {
    font-family: Outfit;
    font-size: 14px;
    font-weight: 600;
    color: #333; /* Adjust text color */
}

.Full-Name-Account {
    color: #000;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 44px;
    margin-top: 32px;
}
.delete-account-icon{
    margin-left: 253px;
    margin-top: 30px;
}
.delete-account-icon-header {
    color: var(--Text-h2, #171717);
    text-align: center;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 100%; /* Ensures it doesn't overflow */
    width: 70%; /* Takes the full width of the parent */
    position: absolute; /* Moves it to the lower part */
    top: 30%;/* Adjust this value to position it properly */
    left: 50%; /* Centering the element */
    transform: translateX(-50%); /* Ensures proper centering */
    margin: 0 auto; /* Centers horizontally */
    padding: 10px; /* Adds some spacing */
}
.delete-account-icon-para{
    color: var(--text_non_active, #73607B);
    text-align: center;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-width: 100%; /* Ensures it doesn't overflow */
    width: 67%; /* Takes the full width of the parent */
    position: absolute; /* Moves it to the lower part */
    top: 50%;/* Adjust this value to position it properly */
    left: 50%; /* Centering the element */
    transform: translateX(-50%); /* Ensures proper centering */
    margin: 0 auto; /* Centers horizontally */
    padding: 10px; /* Adds some spacing */
    padding-left: 20px;
}
.delete-account-container{
    position: relative;
    margin-top: 68px;
    margin-left: 58px;
    width: 583px;
height: 322px;
flex-shrink: 0;
border-radius: 12px;
border: 1px solid rgba(0, 0, 0, 0.20);
background: #FFF;
box-shadow: 90px 417px 119px 0px rgba(0, 0, 0, 0.00), 58px 267px 109px 0px rgba(0, 0, 0, 0.01), 33px 150px 92px 0px rgba(0, 0, 0, 0.05), 14px 67px 68px 0px rgba(0, 0, 0, 0.09), 4px 17px 38px 0px rgba(0, 0, 0, 0.10);
}
.namehere-account {
    margin-left: 44px;
    margin-top: 11px;
    display: inline-flex;
    height: 48px;
    width: 367px;
    padding: 14px 17px 14px 20px;
    justify-content: center;
    align-items: flex-start;
    gap: 149px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
}

.personal-info-account-password {
    align-self: stretch;
    color: var(--Text-h2, #171717);
    font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 210px;
    margin-top: 74px;
}

.personal-info-account-secure {
    color: #545454;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    align-self: stretch;
    margin-left: 210px;
    margin-top: 14px;
}

.button-container-profile {
    display: inline-flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid var(--Primary-500, #C95EBE);

}

.account-button-profile-reset {
    display: inline-flex;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
background: var(--Primary-500, #C95EBE);
    border: 1px solid var(--Primary-500, #C95EBE);
    color: var(--white-100, #EAF7FF);
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 165px;
    height: 35px;
    margin-left: 609px;
    margin-top: -54px;
}

.delete-account-buttons {
    display: flex;
    justify-content: center; /* Centers the buttons horizontally */
    align-items: center;
    gap: 16px; /* Spacing between buttons */
    margin-top: 176px; /* Aligns with the previous button */
}

.account-button-profile-delete-account,
.account-button-profile-cancel {
    display: inline-flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 160px;
    height: 35px;
    cursor: pointer;
}

/* Delete Button */
.account-button-profile-delete-account {
    border: 1px solid rgba(226, 25, 25, 0.75);
    background: #FFF;
    color: rgba(226, 25, 25, 0.75);
}

/* Cancel Button */
.account-button-profile-cancel {
    border-radius: 12px;
background: var(--reeedd, rgba(226, 25, 25, 0.75));
color: var(--Bg-Color, #FAF9F6);
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.edit-icon-container {
    display: flex;
    align-items: center; /* Align icon and text */
    cursor: pointer;
    gap: 6px; /* Space between icon and text */
}

.edit-icon-account-fullname {
    width: 16px; /* Adjust size as needed */
    height: 16px;
}

.edit-text {
    font-family: Outfit;
    font-size: 14px;
    font-weight: 600;
    color: #333; /* Adjust text color */
}



.logout-icon-account{
    margin-left: 360px;
    margin-top: 30px;
}

.logout-account-btton {
    color: #D31C1C;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 390px;
    margin-top: -20px;
}

.popup-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    width: 163px;
    height: 88px;
    padding: 12px;
    border-radius: 0px 12px 12px 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.1), 7px 11px 13px rgba(0, 0, 0, 0.09);
    position: absolute;
    top: 85px;
    left: 60px;
    z-index: 10;
}

.popup-option {
    display: flex;
    width: 139px;
    height: 30px;
    padding: 5px 52px 5px 12px;
    align-items: flex-start;
    gap: 12px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 0.5px solid rgba(0, 0, 0, 0.10);
}

.popup-option:hover {
    border: 1px solid black;
    background: #FFF;
    border-radius: 6px;
    padding: 5px;
}

.popup-icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

/* Billing Section Styles - Prefixed with billing- to avoid conflicts */

.billing-wrapper {
    
    padding-top: 10px;
    font-family: 'Outfit', sans-serif;
   width: 80%;
  }
  
  .billing-upgrade-banner {
    background-color: #f4ecff;
    border-radius: 12px;
    padding: 1.5rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
   
    width: 96%;
    height: 160px;
flex-shrink: 0;
border-radius: 6px;
background: linear-gradient(93deg, #6F52ED 0.61%, #9C79D7 74.54%);
box-shadow: 0px 54px 15px 0px rgba(0, 0, 0, 0.00), 0px 34px 14px 0px rgba(0, 0, 0, 0.01), 0px 19px 12px 0px rgba(0, 0, 0, 0.05), 0px 9px 9px 0px rgba(0, 0, 0, 0.09), 0px 2px 5px 0px rgba(0, 0, 0, 0.10);
  }
  
  .billing-upgrade-left {
    max-width: 60%;
  }
  
  .billing-upgrade-title {
    color: #FFF;

font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: -64px;

  }
  .billing-plan-box-title{
    color: var(--Text-h1, #18101C);
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 20px;
  }
  .billing-upgrade-subtitle {
    color: #FFF;
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  
  .billing-upgrade-btn {
    display: inline-flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 9px;
background: var(--White, #F5F4F3);
    cursor: pointer;
    position: absolute;
    bottom: 1.5rem;
    left: 1.5rem;
    color: #8B55DD;
text-align: center;
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  
  .billing-upgrade-arrow {
    margin-left: 6px;
  }
  
  .billing-upgrade-image img {
    width: 305px;
    position: absolute;
    right: 0.5rem;
    bottom: 0rem;
  }
  
  .billing-plan-box {
   
    width: 96%;
   
    margin-top: 5px;
    height: 225px;
align-self: stretch;
    border-radius: 6px;
border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
background: var(--Background-main, #FEF9F7);
  }
  
  .billing-plan-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .billing-feature-columns {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    
  }
  
  .billing-feature-list {
    list-style: disc inside;
    padding: 0;
    margin: 0;
    flex: 1;
  }
  
  
  .billing-plan-title {
    color: var(--Secc, #8B55DD);
font-family: Outfit;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left: 24px;
margin-top: 16px;
  }
  
  .billing-trial-days-left {
    display: inline-flex;
padding: 6px 12px;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: 20px;
border: 1px solid var(--sec_shade, rgba(139, 85, 221, 0.16));
    font-size: 0.85rem;
    color: #6a36d9;
    margin-right: 12px;
    margin-top: 12px;
  }
  
  
  .billing-price {
    color: var(--Text-h1, #18101C);
font-family: Outfit;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
    margin-top: 16px;
    margin-left: 24px;
  }
  
  .billing-per-month {
    color: var(--Neutral_2, #52525B);
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
    margin-left: 4px;
  }
  
  .billing-feature-list {
  
    
    padding-left: 1.8rem;
    font-size: 0.95rem;
    color: #444;
    
  }
  .billing-feature-list li{
    padding-top: 0.8rem;
    color: var(--text_non_active, #73607B);
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  .billing-trial-note {
    color: var(--Secc, #8B55DD);
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .billing-trial-note-highlights{
    color: var(--Primary_400, #A04A97);
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  
  .billing-bottom-cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }
  
  .billing-upgrade-link {
    color: var(--Neutral-1, #3F3F46);
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left: 28px;
  }
  .billing-upgrade-link strong{
    color: var(--Secc, #8B55DD);
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: none;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
  }
  .billing-cancel-btn {
    color: var(--reeedd, rgba(226, 25, 25, 0.75));
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .billing-usage-box {
    width: 96%;
    border-radius: 6px;
border: 1px solid rgba(0, 0, 0, 0.10);
background: #FFF;
    padding-left: 1rem;
padding-top: 1rem;
    font-size: 0.95rem;
  }
  .billing-usage-box-headings{
    color: var(--Text-h1, #18101C);
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  
  .billing-usage-row span{
    color: var(--Neutral_2, #52525B);
    font-family: Outfit;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    
  }
  
  .billing-usage-bar {
    width: 100%;
    height: 8px;
    background-color: #e0e0e0;
    border-radius: 6px;
    overflow: hidden;
   
  }
  
  .billing-usage-fill {
    background-color: #9c5eff;
    height: 100%;
    border-radius: 6px 0 0 6px;
  }
  
  .billing-limit-warning {
    margin-top: 1rem;
    font-size: 0.9rem;
    color: #ff5e5e;
    font-weight: 500;
  }
  .billing-usage-grid {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    flex-wrap: wrap;
  }
  .billing-usage-grid-month {
   
  }
  
  .billing-usage-left,
  .billing-usage-right {
    flex: 1;
    min-width: 250px;
  }
  
  .billing-usage-row {
    margin-bottom: 1rem;
    
  }
  
  .date-billing {
    color: var(--Neutral_2, #52525B);
    font-family: Outfit;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: auto; 
    
  }
  .date-billing-month {
    color: var(--Neutral_2, #52525B);
    font-family: Outfit;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 745px;
  }
  
  
  .billing-usage-bar {
    background: #e0e0e0;
    height: 8px;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 8px;
  }
  .billing-usage-bar-month {
    background: #e0e0e0;
    width: 57rem;
    height: 11px;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 8px;
  }
  .billing-usage-fill {
    border-radius: 12px;
    background: var(--strokw_odd, linear-gradient(90deg, #F3904F 30.06%, #8B55DD 100%));
    height: 100%;
    border-radius: 4px 0 0 4px;
  }
  
  .billing-limit-warning {
    color: #E95150;
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
    margin-top: 50px;
    margin-left: 60px;
  }
  .billing-limit-warning-month {
    color: #E95150;
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
    margin-top: 20px;
    margin-left: 500px;
  }
  .custom-popup-title-billing{
    color: var(--Text-h1, #18101C);
font-family: Outfit;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
align-self: stretch;
margin-left: -184px;
  }
  .custom-popup-subtext-billing{
    color: var(--Neutral_2, #52525B);
    font-family: Outfit;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    
    text-align: left; 
  }
  .check-icon-billing-para{
    color: var(--text_non_active, #73607B);
font-family: Outfit;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 5px;
  }

  .coming-soon-label {
    color: #c95ebe;
    font-weight: 600;
    margin-bottom: 12px;
  }
  .enterprise-plan-card{
    margin-top: -340px;
    margin-left: 475px;
  }
  .enterprise-card-box {
    

    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    box-shadow: -56px 171px 50px 0px rgba(0, 0, 0, 0.00), -36px 110px 46px 0px rgba(0, 0, 0, 0.01), -20px 62px 39px 0px rgba(0, 0, 0, 0.05), -9px 27px 29px 0px rgba(0, 0, 0, 0.09), -2px 7px 16px 0px rgba(0, 0, 0, 0.10);
    width: 794px;
    height: 296px;

  }
  
  .enterprise-title {
    color: #000;
    text-align: center;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    align-self: stretch;
    margin-top: 10px;
  }
  
  .enterprise-subtitle {
    color: var(--Secc, #8B55DD);
text-align: center;
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 700;
line-height: normal;
    margin-top: 4px;
  }
  
  .enterprise-divider {
    width: 730px;
    height: 2.2px;
    background: linear-gradient(180deg, #C95EBE 0%, #8B55DD 100%);
    margin-left: 30px;
    margin-top: 10px;
  }
  
  .feature-tag {
    display: inline-flex;
padding: 6px 16px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: 24px;
background: rgba(187, 66, 174, 0.24);
color: var(--Primari_300, #BB42AE);
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.28px;
margin-left: 30px;
  }
  
  .enterprise-feature-list {
    list-style: none;
    padding: 0;
    
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    color: #383838;
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left: 30px;
margin-top: 20px;
  }
  
  .enterprise-feature-list li {
    display: flex;
   
    font-size: 14px;
  }
  
  .integration-row {
    margin-top: 20px;
    grid-column: span 2;
    flex-wrap: wrap;
  }
  
  .integration-links {
    color: #8c42b9;
    margin-left: 6px;
    font-weight: 500;
  }
  
  .contact-sales-btn {
    display: flex;
width: 731px;
padding: 12px 0px;
justify-content: center;
align-items: center;
gap: 12px;
border-radius: 24px;
border: 1px solid var(--Primary-500, #C95EBE);
color: var(--Primary-500, #C95EBE);
text-align: center;
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 30px;
margin-left: 30px;
  }
  
  .contact-sales-btn:hover {
    background-color: #fceafd;
  }
  .payment-processing-message {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: #f8f8f8;
    border: 1px solid #ccc;
    padding: 12px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    z-index: 9999;
    font-weight: 500;
    font-size: 14px;
    color: #333;
  }
  