    .date-details-invoice {
      display: flex;
      gap: 1rem;
      column-gap: 20.625rem;
      padding-bottom: 0.9375rem;

      margin-top: 10px;
      margin-left: 30px;
    }

    .contract-icon-invoice {
      width: 1.5rem;
      height: 1.5rem;
      margin-top: -66px;
      margin-left: 11px;
  }
  .button-icon-invoice{
    color: var(--Neutral_2, #52525B);
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: var(--Typography-Line-Height-300, 24px); /* 184.615% */
letter-spacing: 0.13px;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: none;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
gap: 5px;
cursor: pointer;
  }
  .customer-details-invoice {
    display: flex;
    justify-content: space-between;
    gap: 1.25rem;
    margin-top: -1.25rem;
    background-color: #fefaf7;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    margin-left: 28px;
    margin-top: 10px;
  }
  .payment-info-title-invoice{
    color: var(--Text-h1, #18101C);
font-family: Outfit;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left: 1px;
  }
  .custom-placeholder-invoice::placeholder{
    color: var(--text_non_active, #73607B);
font-family: Outfit;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .accordion-item-invoice {
    border: 1px solid #e0e0e0;
    border-radius: 0.5rem;
    margin-bottom: 0.625rem;
    width: 97.5%;
    background: #fff;
    max-height: 25rem;
    overflow: hidden;
    margin-left: 30px;
  }
  
  .customer-card-invoice {
    background: #ffffff;
    padding: 1.25rem;
    border-radius: 0.75rem;
    background: #FFF;
    width: 50%;
    
  }
  .signatory-invoice,
.address {
  color: var(--text_non_active, #73607B);
  /* font-family: Outfit; */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  
}
.customer-card-invoice .signatory-invoice {
  margin-top: 0.8375rem;
}
  .date-details-invoice {
    
    grid-template-columns: repeat(3, 1fr);
    gap: 6rem;
    column-gap: 3.625rem;
    padding-bottom: 0.9375rem;
    border-bottom: 1px solid #ddd;
    margin-bottom: 0.9375rem;
    margin-top: 10px;
    margin-left: 30px;
  }
  .date-details-invoice p {
   
    flex-direction: column; /* Stack heading and data vertically */
    margin: 0; /* Remove extra margins */
  }
  
  .date-details-invoice p .keyValue {
    margin-top: 0.25rem; /* Add a small gap between heading and data */
    font-size: 14px; /* Slightly smaller font for data */
    color: #73607B; /* Match the color of the data */
  }
  .date-details-invoice .effective {
    color: var(--Neutral-1, #3F3F46);
    font-family: Outfit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .date-details-invoice .keyValue {
    color: var(--Text-h1, #18101C);
    font-family: Outfit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .customer-card-invoice h3 {
    font-size: 1rem;
    color: #333;
    margin-bottom: 0.625rem;
    font-weight: bold;
  }
  
  .customer-card-invoice p {
    font-size: 0.875rem;
    color: #555;
    margin: 0.3125rem 0;
  }
  
  
  
  .customer-card-invoice p strong {
    font-weight: bold;
  }
  
  .customer-card-invoice span[role="img"] {
    margin-right: 0.5rem;
    color: #d26ac2;
  }
  
  .customer-card-invoice .party-label {
    color: var(--Text-h1, #18101C);
    font-family: Outfit;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0.625rem;
    display: inline-block;
  }
  
  .customer-card-invoice .signatory {
    margin-top: 0.9375rem;
  }
  
  .customer-card-invoice .address,
  .customer-card-invoice .contact {
    margin: 0rem;
    margin-top: 7px;
  }
  .customer-header-invoice {
    color: var(--Text-h1, #18101C);
    font-family: Outfit;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0.9375rem;
    display: flex;
    /* align-items: center; */
    margin-left: 2rem;
  }
    .cust-details-invoice {
      color: var(--text_non_active, #73607B);
      font-family: Outfit;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      /* margin-left: 1.25rem; */
      margin-left: 23px;
      margin-top: -7px;
  }
    .date-details-invoice p {
      /* display: flex; */
      flex-direction: column;
      margin: 0;
    }

    .date-details-invoice p .keyValue {
      margin-top: 0.25rem;
      font-size: 14px;
      color: #73607B;
      margin-left: 10px;
    }

    .date-details-invoice .effective {
      color: var(--Neutral-1, #3F3F46);
      font-family: Outfit;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 6px;
      /* display: inline-flex; */
padding: 12px 24px;
align-items: center;

border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
    }

    .header-content-invoice {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      border-radius: 6px;
    border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
    background: #FFF;
    width: 100%;
  max-width: 2000px;
    height: 17.9vh;
    flex-shrink: 0;
    margin-top: -37px;
    margin-left: 15px;
    box-sizing: border-box;
    }

    .date-details-invoice .keyValue {
      color: var(--Text-h1, #18101C);
      font-family: Outfit;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 10px;
    }

   

    .line-items-wrapper h3 {
      color: var(--Text-h1, #18101C);
      font-family: Outfit;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-left: 33px;
      margin-top: 15px;
    }

    .line-items-wrapper table {
      border-radius: 12px 0px 0px 0px;
      border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
      width: 95.6%;
      border-collapse: collapse;
      margin-bottom: 16px;
      margin-top: 10px;
      margin-left: 35px;
    }

    .line-items-wrapper table th,
    .line-items-wrapper table td {
      border: 1px solid #ddd;
      text-align: left;
      padding: 8px;
    }

    .line-items-wrapper table th {
      background-color: #f9f9f9;
      font-weight: bold;
    }

    .line-items-wrapper table tbody tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    .line-items-wrapper table tbody tr:hover {
      background-color: #f1f1f1;
    }


    .line-items-wrapper button:hover {
      background-color: #ddd;
    }

    .line-items-wrapper button:active {
      background-color: #ccc;
    }

    .line-items-wrapper button.add-item {
      background-color: #4caf50;
      color: white;
      font-weight: bold;
    }

    .line-items-wrapper button.add-item:hover {
      background-color: #45a049;
    }

    .line-items-wrapper button.add-item:active {
      background-color: #3e8e41;
    }

    .line-items-wrapper button.edit {
      background-color: #2196f3;
      color: white;
    }

    .line-items-wrapper button.edit:hover {
      background-color: #1976d2;
    }

    .line-items-wrapper button.delete {
      background-color: #f44336;
      color: white;
    }

    .line-items-wrapper button.delete:hover {
      background-color: #d32f2f;
    }

    .payment-informations {
      display: flex;
    }
    .payment-informations img{
      margin-top: 15px;
      margin-left: 8px;
    }

    .add-item-invoice {
      display: inline-flex;
      padding: 5px 12px;
      align-items: center;
      gap: 4px;
      border-radius: 8px;
      border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
      background: var(--Background-main, #FEF9F7);
      color: var(--Primary-500, #C95EBE);
      font-family: Outfit;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 33px;
    }

    /* @media (min-width: 1280px) and (max-width: 1440px) {
      .header-content-invoice{
        width: 74.5vw;
      }
      .key-dates-invoice {
        width: 75.2vw;
      }
      .line-items-wrapper{
        width: 960px;
      }
      
    }

    @media (min-width: 1440px) and (max-width: 1536px) {
      .header-content-invoice {
        width: 77.5vw;
    }
    .key-dates-invoice {
      width: 78vw;
  }
  .line-items-wrapper {
    width: 1115px;
}
    }

    @media (min-width: 1536px) and (max-width: 1920px) {
      .header-content-invoice {
        width: 78.6vw;
    }
    .key-dates-invoice {
      width: 78.8vw;
  }
  .line-items-wrapper {
    width: 1204px;
}
    }
    @media (min-width: 1920px) and (max-width: 2560px) {
      .header-content-invoice {
        width: 80.8vw;
    }
    .key-dates-invoice {
      width: 81.5vw;
  }
  .line-items-wrapper {
    width: 1557px;
}
      
    }  */