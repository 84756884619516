.page-container{
  font-family: "Inter", sans-serif;
  background-color: #fff;
}
.main{
  position: absolute;
}
.reset-password-container {

    align-items: center;
    width: 411px;
    height: 564px;
    flex-shrink: 0;
    border-radius: 12px;
border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
background: #FFF;
margin-left: 550px;
margin-top: 20px;
  }
  
  .reset-password-box {
    
    padding: 40px;
    border-radius: 8px;
    text-align: center;
    width: 100%;
    max-width: 450px;
  }
  
  .logo {
    width: 64.425px;
  height: 64.425px;
  flex-shrink: 0;
  }
  
  .reset-title {
    color: var(--Text-h2, #171717);
    text-align: center;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .subtitle {
    color: var(--Neutral_2, #52525B);
    text-align: center;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.14px;
  }
  
  .form-group {
    margin-bottom: 32px;
    text-align: left;
  }
  
  /* input {
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  } */
  .password-input{
    width: 355px;
    height: 52px;
    flex-shrink: 0;
    padding: 10px;
    margin-top: 8px;
    color: rgba(115, 96, 123, 0.60);
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    padding: 15px;
    font-size: 16px;
    border-radius: 6px;
    border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
    margin-left: 101px;
  }
  .input-hint {
    color: var(--text_non_active, #73607B);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 103px;
  }
  .show-pass{
    left: 420px;
    right: 24px;
   }
   .confirm-forgot-option{
    color: var(--Neutral_2, #52525B);
font-family: Outfit;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left: 100px;
   }

  .reset-password-btn {
    margin-left: 100px;
    display: flex;
height: 52px;
padding: 24px 120px;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
border-radius: 6px;
background: var(--Secc, #8B55DD);
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
   
    width: 368px; /* Ensure button width adapts to the content */
    white-space: nowrap; /* Prevent text from wrapping */
    transition: 0.7s;
  }
  
  .reset-password-btn:hover {
    background-color: #7a46d0;
  }
  .reset-password-btn:active{
    scale: 0.95;
  }
  .back-link {
    /* display: block; */
    margin-top: 20px;
    /* color: #7a7a7a; */
    font-family: "Inter", sans-serif;
    color: #52525B;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.42px;
  }
  
  .back-link a {
    color: #8B55DD;
    text-decoration: none;
  }
  
  .error-message {
    color: red;
  }
  
 
  

  /* Successfully reset page css */

  /* Success page styles */
.success-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height for centered content */
  background-color: #f9f9f9; /* Light background for the success page */
  text-align: center; /* Center text */
}

.success-icon {
  margin-bottom: 20px; /* Space below the success icon */
}

.success-icon img {
  width: 60px; /* Adjust size of success icon */
  height: 60px;
}

.success-page h1 {
  font-size: 24px;
  font-weight: bold;
  color: #333; /* Dark text color for visibility */
  margin-bottom: 10px;
}

.success-page p {
  font-size: 16px;
  color: #666; /* Lighter text color for the paragraph */
  margin-bottom: 30px;
}

.back-login-btn {
  padding: 16px 224px;
  gap: 10px;
  align-self: stretch;

  background-color: #8B55DD; /* Purple color for the button */
  color: white;
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease; /* Smooth hover transition */
}

.back-login-btn:hover {
  background-color: #6b46c1; /* Darker purple on hover */
}
.back-login-btn:active{
  scale: 0.95;
}