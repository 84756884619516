
/* *{
    font-family: "Inter", sans-serif;
} */
.plan-page {
    background: var(--Background-main, #FEF9F7);
    /* background: #fff; */
}
.top-planheading{
    color: var(--Text-h1, #18101C);
text-align: center;
font-family: Outfit;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.title-features-pay{
    color: #383838;
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.des-features-pay{
    color: #383838;
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: 20px;
}
.offers-pay-terms{
    margin-top: -10px;
    margin-left: -7.9px;
    width: 156px;
height: 28px;
flex-shrink: 0;
border-radius: 16px 0px;
background: #ECE0FD;
color: #8B55DD;
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.offers-text-pay {
    padding: 5px 10px; /* Adjust padding as needed */
    display: inline-block; /* Ensures the padding applies properly */
  }
  
  .pay-original-price{
    color: var(--Primari_300, #BB42AE);
text-align: center;
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: strikethrough;
  }

  .pay-discount-price{
    color: #000;
text-align: center;
font-family: Outfit;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
  }
  .comming-sson-pay{
    color: var(--Neutral_2, #52525B);
text-align: center;
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 700;
line-height: normal;
align-self: stretch;
margin-top: -10px;
  }

  .pay-discount-price-des{
    color: var(--Neutral_2, #52525B);
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;

  }

.included-pay{
    width: 181px;
    height: 22px;
    display: inline-flex;
padding: 6px 14px;
justify-content: center;
align-items: center;
/* gap: 6px; */
border-radius: 24px;
background: rgba(187, 66, 174, 0.24);
}

.top-planheading-bref{
    width: 469px;
    color: var(--Neutral_2, #52525B);
text-align: center;
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 19px;
margin-left: 500px; /* 118.75% */
}
.progress-Bar{
    width: 1207px;
    height: 9px;
    flex-shrink: 0;
    border-radius: 14px;
    background: #D9D9D9;
}
.progress-active{
    width: 842px; 
    height: 9px;
    background-color: #BB42AE;
    border-radius: 14px; 
}
/* .plan-top{
    color: #18101C;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
} */
.plan-subtop{
    color:  #52525B;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


/* .monthly-yearly-box{
    width: 331px;
    height: 64px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.24);
    background-color: #FFF;
} */
.monthly-text{
    color:  #52525B;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.yearly-text{
    color:  #52525B;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.active-box {
    border-radius: 6px;
    border: 1px solid var(--Secc, #8B55DD);
    background: var(--White, #F5F4F3);
    box-shadow: 83px 86px 33px 0px rgba(0, 0, 0, 0.00), 
                53px 55px 31px 0px rgba(0, 0, 0, 0.01), 
                30px 31px 26px 0px rgba(0, 0, 0, 0.05), 
                13px 14px 19px 0px rgba(0, 0, 0, 0.09), 
                3px 3px 11px 0px rgba(0, 0, 0, 0.10);
  }
  
  .monthly-active-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 93px;
    height: 52px;
    flex-shrink: 0;
  }
  
  .yearly-active-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 207px;
    height: 52px;
    flex-shrink: 0;
  }
  
.free-text{
    display: inline-flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background-color: rgba(139, 85, 221, 0.30);
    color:  #8B55DD;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.card{
    box-shadow: rgba(208, 204, 204, 0.35) 0px 5px 15px;
    border-radius: 16px;
    /* border: 1px solid rgba(0, 0, 0, 0.10); */
    background: #FFF;
}

.offers{
    width: 157px;
    height: 28px;
    flex-shrink: 0;
    border-radius: 4px;
    /* background: #ECE0FD; */
    color: var(--Secc, #8B55DD);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.card-header{
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.plan-des{
    color: var(--Secc, #8B55DD);
    text-align: center;
    font-family: Outfit;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 5px;
}
.card-sub-header{
    color:  #18101C;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.card-price{
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.monthly-yearly{
    color:  #52525B;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.card-btn{
    display: flex;
    padding: 12px 89px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 24px;
    /* border: 1px solid var(--Secc, #8B55DD); */

    /* color: var(--Secc, #8B55DD); */
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.credit-text{
    color: #2C1338;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.card-processBar{
    width: 339px;
    height: 1px;
    background: linear-gradient(180deg, #C95EBE 0%, #8B55DD 100%);
}
.card-include{
    color: var(--Primari_300, #BB42AE);
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
}
.card-users{
    width: 143px;
    height: 28px;
    display: flex;
    padding: 4px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
    background: rgba(187, 66, 174, 0.16);
    color:  #BB42AE;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.card-points-container{
    color:  #3F3F46;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.points-container {
    max-height: 240px; /* Adjust as needed */
    overflow-y: auto;
    padding-right: 10px; /* Prevent text cutoff */
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 6.5px; /* Adjust this value to control the spacing */
}
  /* Optional: Style scrollbar */
  .points-container::-webkit-scrollbar {
    width: 5px;
  }
  
  .points-container::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  
  .points-container::-webkit-scrollbar-track {
    background: transparent;
  }
  

/* payment success page */
.text-paymentpage{
    display: flex;
    width: 415px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.payment-container{
    border: 1px solid rgba(0, 0, 0, 0.20);
    box-shadow: 90px 417px 119px 0px rgba(0, 0, 0, 0.00), 58px 267px 109px 0px rgba(0, 0, 0, 0.01), 33px 150px 92px 0px rgba(0, 0, 0, 0.05), 14px 67px 68px 0px rgba(0, 0, 0, 0.09), 4px 17px 38px 0px rgba(0, 0, 0, 0.10);
}

.home-btn{
    font-family: "Outfit", sans-serif;
}
.coming-soon-text{
    background: linear-gradient(90deg, #C95EBE 2.53%, #8B55DD 23.14%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.custom-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: flex-start; /* Aligns content to the left */
    align-items: center;
    padding-left: 587px;
    padding-top: 100px; /* Adjust left spacing without creating extra space */
    z-index: 1000;
}
.disabled-popup-button {
    opacity: 0.6;
    pointer-events: none;
    cursor: not-allowed;
  }
  

/* ======= POPUP CONTENT ======= */
.popup-content {
    background: white;
    padding: 25px;
    border-radius: 10px;
    width: 420px;
    /* text-align: center; */
    position: relative;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

/* Close Button */
.close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
}

/* ======= POPUP TEXT STYLES ======= */
.custom-popup-title {
    color: var(--Neutral_2, #52525B);
/* text-align: center; */
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 700;
line-height: normal;

}

.custom-popup-subtext {
    color: var(--Neutral_2, #52525B);
    font-family: Outfit;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    align-self: stretch;
  
}

/* ======= PLAN DETAILS ======= */
.popup-plan-details {
    width: 372px;
height: 201px;
flex-shrink: 0;
border-radius: 6px;
border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
background: var(--Background-main, #FEF9F7);
margin-top: 16px;
}

.popup-plan-header {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}

.popup-free {
    width: 117px;
height: 27px;
flex-shrink: 0;
border-radius: 6px 0px 9px 0px;
color: var(--Secc, #8B55DD);
text-align: center;
font-family: Outfit;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.24px;
padding: 5px;
background: var(--sec_shade, rgba(139, 85, 221, 0.16));
}

.popup-free-monthly {
    width: 117px;
height: 27px;
flex-shrink: 0;
color: var(--Secc, #8B55DD);
text-align: center;
font-family: Outfit;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.24px;
padding: 5px;
border-radius: 6px 0px 9px 0px;
background: var(--sec_shade, rgba(139, 85, 221, 0.16));}
.payment-plan{
    color: var(--Neutral_2, #52525B);
text-align: center;
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.popup-extra-text {
    font-size: 13px;
    color: #73607B;
    text-align: left;  /* Aligns text to the left */
    margin-top: 10px;
    white-space: normal; /* Allows wrapping */
    max-width: 100%; /* Ensures text doesn't overflow */
    line-height: 1; /* Improves readability */
    text-indent: 0px; /* Ensures the first line starts from the same position */
    padding-left: 8px; /* Adjusts left padding for alignment */
}

  
.popup-plan-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates two equal columns */
    gap: 8px; /* Adjust space between columns */
    text-align: left;
    width: 100%;
  }
  
  .popup-plan-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  
  .popup-value {
    color: var(--Text-h1, #18101C);
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
.popup-billed {
    color: #8B55DD;
    background: #F6F6F6;
    padding: 5px;
    border-radius: 4px;
}

.popup-plan-info p {
    font-size: 14px;
    color: #333;
    margin: 5px 0;
}

.popup-verification-text {
    font-size: 12px;
    color: #777;
    margin-top: 8px;
}

/* ======= TERMS AND BUTTON ======= */
.popup-terms {
    color: var(--Text-h2, #171717);
    font-family: Outfit;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.popup-button {
    display: flex;
    flex-direction: column; /* Ensures items appear in separate lines */
   
    background: #8B55DD;
    color: white;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    height: 64px;
}

.popup-button .popup-button-payment {
    color: var(--White, #F5F4F3);
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
    text-align: center;
    background: transparent;
    border: none;
    cursor: pointer;
}

.popup-button p {
    color: rgba(245, 244, 243, 0.80);
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.popup-button {
    background: #8B55DD;
    color: white;
    padding: 12px 20px;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
}

.popup-button:hover {
    background: #7a45cc;
}

.popup-payment-note {
    font-size: 12px;
    color: #777;
    margin-top: 8px;
}


.activation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(18, 18, 18, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
  }
  
  .activation-popup {
   
    padding: 2rem 2rem 2.5rem;
    text-align: center;
    width: 600px;
height: 240px;
border-radius: 12px;
background: #FFF;
box-shadow: 219px 363px 119px 0px rgba(0, 0, 0, 0.00), 140px 232px 108px 0px rgba(0, 0, 0, 0.01), 79px 131px 91px 0px rgba(0, 0, 0, 0.05), 35px 58px 68px 0px rgba(0, 0, 0, 0.09), 9px 15px 37px 0px rgba(0, 0, 0, 0.10);
    animation: fadeIn 0.3s ease;
  }
  
  .confetti-icon {
    height: 40px;
    /* margin-bottom: 16px; */
  }
  
  .activating-btn {
    display: inline-flex;
    padding: 10px 28px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
opacity: 0.72;
background: var(--nwww-strrkk, linear-gradient(93deg, #6F52ED 0.64%, #845EC2 99.52%));
margin-top: 1rem;
color: #FFF;
text-align: center;
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 19px; /* 135.714% */
letter-spacing: 0.14px;
  }
  .activation-popup h2{
    color: var(--Text-h1, #18101C);
text-align: center;
font-family: Outfit;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 19px; /* 95% */
letter-spacing: 0.2px;
  }
  .activation-popup p{
    margin-top: 0.6rem;
    color: var(--Neutral-1, #3F3F46);
text-align: center;
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 19px; /* 135.714% */
letter-spacing: 0.14px;
  }
  
  .activating-btn:disabled {
    background-color: #c4b5fd;
    cursor: wait;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: scale(0.95); }
    to { opacity: 1; transform: scale(1); }
  }
  