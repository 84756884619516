.upload-button-spread {
    width: 187px;
    height: 40px;
    display: inline-flex
;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 0.375rem;
    border: 1px solid var(--Primary-500, #C95EBE);
    color: var(--Primary-500, #C95EBE);
    cursor: pointer;
    margin-left: 33.3125rem;
    margin-top: 2.6625rem;
}