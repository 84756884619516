
.full-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header-container {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 1.25rem;
  color: #000;
  font-family: outfit;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.contract-details {
  padding: 1.25rem;
  width: 100%;
  padding-left: 13.75rem;
  background: #fefaf7;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #fefaf7;
  margin-bottom: 1.25rem;
}



.contract-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.contract-icon {
  width: 1.5rem;
  height: 1.5rem;
 margin-top: -42px;
 margin-left: -15px;
}

.contract-title {
  color: #000;
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: -1.5625rem;
  width: 500px;
}
.contract-title-invoice {
  color: #000;
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
 
  width: 500px;
}

.contract-id,
.contract-type {
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0.25rem 0;
}

.contract-id span,
.contract-type span {
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.view-contract-button-contract {
  display: inline-flex;
  padding: 0.5rem 0.375rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  border-radius: 0.25rem;
  background: var(--Primary-500, #C95EBE);
  color: var(--White, #F5F4F3);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.01rem;
  position: absolute; 
  top: 0; 
  right: 1rem;
  transform: translateY(-50%);
}
.view-contract-button-invoice {
  display: inline-flex;
  padding: 10px 28px;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  border-radius: 6px;
  background: linear-gradient(91deg, #8B55DD 7.29%, #9177B8 99.49%);
  color: var(--White, #F5F4F3);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.01rem;
  transform: translateY(-50%);
  margin-left: auto; 
  margin-right: 16px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 1rem;
  background-color: #fefaf7;
}


.view-contract-button:hover {
  background-color: #b059a4;
}

.button-icon img {
  width: 1.15rem;
  height: 1.15rem;
  margin-top: 3px;
}

.key-dates {
  padding: 1.25rem;
  width: 100%;
  margin: 0 auto;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.05);
  border-radius: 0.75rem;
  box-sizing: border-box;
  overflow: hidden; 
}


.key-dates-header {
  margin-bottom: 0.9375rem;
}

.key-dates-title {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.calendar-icon-contract {
  width: 2.6rem;
  height: 2.6rem;
 
}
.calendar-icon-contract img{
  margin-top: 0px;
  margin-left: 10px;
}
.key-dates h3 {
  color: var(--Text-h1, #18101C);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.key-dates-description {
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0.25rem;
}

.date-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  column-gap: 10.625rem;
  padding-bottom: 0.9375rem;
  border-bottom: 1px solid #ddd;
  margin-bottom: 0.9375rem;
  margin-top: 10px;
  margin-left: 30px;
}
.date-details p {
  display: flex;
  flex-direction: column; /* Stack heading and data vertically */
  margin: 0; /* Remove extra margins */
}

.date-details p .keyValue {
  margin-top: 0.25rem; /* Add a small gap between heading and data */
  font-size: 14px; /* Slightly smaller font for data */
  color: #73607B; /* Match the color of the data */
}
.date-details .effective {
  color: var(--Neutral-1, #3F3F46);
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.date-details .keyValue {
  color: var(--Text-h1, #18101C);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.milestone-section {
  margin-top: 2.25rem;
  padding-left: 2rem;
}

.milestone-header {
  display: flex;
  justify-content: space-between;
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.625rem;
  white-space: nowrap;
}

.toggle-button {
  margin-right: 62.5rem;
  font-size: 1.125rem;
  color: #777;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s;
}

.toggle-button:hover {
  color: #333;
}

.milestone-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.milestone-card {
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
}

/* .milestone-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
} */

.calendar-icon-contract {
  font-size: 1.125rem;
  margin-right: 0.5rem;
}

.milestone-date {
  color: var(--Text-h1, #18101C);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.view-more {
  color: var(--Primary-500, #C95EBE);
  font-family: Outfit;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  margin-left: 0.625rem;
  white-space: nowrap;

}

.view-more:hover {
  text-decoration: underline;
}

.milestone-description {
  color: var(--Text-h1, #18101C);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 10px;
  margin-top: -12px;
}

.customer-details {
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
  margin-top: -1.25rem;
  background-color: #fefaf7;
  padding: 1.25rem;
  border-radius: 0.5rem;
}

.customer-header {
  color: var(--Text-h1, #18101C);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.9375rem;
  display: flex;
  align-items: center;
}

.customer-header span[role="img"] {
  margin-right: 0.5rem;
}

.customer-cards {
  display: flex;
  justify-content: center;
  gap: 1.25rem;
  width: 100%;
  margin-left: 0.625rem;
}

.customer-card {
  background: #ffffff;
  padding: 1.25rem;
  border-radius: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #FFF;
  width: 50%;
  box-shadow: 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.1);
}
.mile-date1{
  padding-left: 53px;
}
.customer-card h3 {
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.625rem;
  font-weight: bold;
}

.customer-card p {
  font-size: 0.875rem;
  color: #555;
  margin: 0.3125rem 0;
}



.customer-card p strong {
  font-weight: bold;
}

.customer-card span[role="img"] {
  margin-right: 0.5rem;
  color: #d26ac2;
}

.customer-card .party-label {
  color: var(--Text-h1, #18101C);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.625rem;
  display: inline-block;
}

.customer-card .signatory {
  margin-top: 0.9375rem;
}

.customer-card .address,
.customer-card .contact {
  margin: 0rem;
  margin-top: 7px;
}
.customer-name {
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.signatory,
.address {
  color: var(--text_non_active, #73607B);
  /* font-family: Outfit; */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 1.875rem;
}

.contact-info {
  display: flex;
  align-items: center;
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #555;
  gap: 0.5rem;
  margin-left: 1.925rem;
  margin-top: 0.625rem;
}

.email-img {
  width: 1.5625rem;
  height: 1.5625rem;
}

.email-img2 {
  width: 1.0625rem;
  height: 1.0625rem;
}

.signatory-name {
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.cust-details {
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* margin-left: 1.25rem; */
  margin-left: 98px;
  margin-top: -7px;
}
.cust-details-signatory{
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* margin-left: 1.25rem; */
  margin-left: 20px;
  margin-top: -7px;
}
.cust-details-customer-a{
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: -6px; 
  margin-left: 57px;
}
.cust-details-customer{
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* margin-left: 1.25rem;
  margin-right: 270px;*/
  margin-top: -8px; 
  margin-left: 55px;
}

.phone-data {
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0.3125rem;
  margin-left: 37px;
}

.email-data {
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 42px;
}

.accordion-item {
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  margin-bottom: 0.625rem;
  width: 73.125rem;
  background: #fff;
  margin-left: 1.875rem;
}

.accordion-header:hover {
  background-color: #f0f0f0;
}

.financial {
  display: flex;
  gap: 0.75rem;
}

.clause {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.clause-header {
  display: flex;
  justify-content: space-between;
  padding: 0.625rem 0;
  cursor: pointer;
  /* border-top: 1px solid #ddd; */
}
.clause-header-contract {
  display: flex;
  justify-content: space-between;
  padding: 0.625rem 0;
  cursor: pointer;
  
}
.clause-header p {
  color: var(--Neutral-1, #3F3F46);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 35px;
}

.clause-content {
  color: var(--Text-h1, #18101C);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.termination-clause-desc{
  color: var(--Text-h1, #18101C);
font-family: Outfit;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-left: 35px;
}
.toggle-clause {
  color: var(--Text-h1, #18101C);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.termination-title{
  color: var(--Neutral-1, #3F3F46);
font-family: Outfit;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left: 35px;
}

.notes-input {
  width: 96%;
  height: 8rem;
  padding: 0.625rem;
  border-radius: 0.25rem;
  border: 1px solid #e0e0e0;
  margin-top: 0.5rem;
  margin-left: 1.8125rem;
  resize: vertical;
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.notes-input::placeholder {
  color: #aaa;
}

.terms-and-conditions-section {
  margin-top: 1.25rem;
}

.notes {
  display: flex;
  gap: 0.75rem;
  color: var(--Text-h1, #18101C);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 2.075rem;
  margin-top: 1.875rem;
}

.accordion-item {
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  margin-bottom: 0.625rem;
  width: 95%;
  background: #fff;
  max-height: 25rem;
  overflow: hidden;
}

.accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0.3125rem;
  color: #333;
  cursor: pointer;
  background: #FFF;
  transition: background-color 0.3s ease;
}
.accordion-header img {
  width: 1.5rem; 
  height: auto;
  margin-left: auto;
}

.toggle-icon-fin,
.toggle-icon-legal {
  margin-left: auto;
}
.accordion-header-financial{
  display: flex;
  align-items: center;
  padding: 0.75rem 0.3125rem;
  color: #333;
  cursor: pointer;
  background: #FFF;
  transition: background-color 0.3s ease;
}

.icon-text {
  display: flex;
  align-items: center;
  color: var(--Text-h1, #18101C);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.icon {
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
}

.accordion-content {
  padding: 0.75rem 1.25rem 0.75rem 0.75rem;
  max-height: 18.75rem;
  overflow-y: auto;
  border-top: none;
  margin-top: 0.625rem;
}

.accordion-content::-webkit-scrollbar {
  width: 0.5rem;
}

.accordion-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0.625rem;
}

.accordion-content::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 0.625rem;
  height: 3.125rem;
  min-height: 1.25rem;
}

.accordion-content::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0;
}


.toggle-icon-term {
  margin-left: 60rem;
}

.toggle-icon-fin {
  margin-left: 61.875rem;
}

.toggle-icon-legal {
  margin-left: 62.8125rem;
}

.clause-content {
  padding: 0.625rem 0;
  font-size: 0.875rem;
  color: #555;
}

.toggle-clause {
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: #777;
  transition: color 0.3s;
}

.toggle-clause:hover {
  color: #333;
}

.financial {
  display: flex;
  gap: 0.75rem;
  color: var(--Text-h1, #18101C);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.accord-cont {
  color: var(--Text-h1, #18101C);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.accordion-header p span[role="img"] {
  margin-right: 0.5rem;
}
.Terms-condition-title{
  color: var(--Text-h1, #18101C);
font-family: Outfit;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.terms-and-conditions-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.contract-duration,
.payment-terms {
  display: flex;
  flex-direction: column;
  width: 45%;
  margin: 0;
  padding: 0;
}

.label-terms {
  color: var(--Neutral-1, #3F3F46);
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 0.25rem 0;
  margin-left: 35px;
}

.value {
  color: var(--Text-h1, #18101C);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 35px;
  width: 916px;
  word-wrap: break-word; /* Ensures long words are wrapped */
  overflow-wrap: break-word; /* Handles word breaking */
  white-space: normal; /* Allows text to wrap */
}


.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
}

.modal-header button {
  padding: 0.3125rem 0.625rem;
  margin: 0 0.3125rem;
  font-size: 0.875rem;
}

.close-button {
  background: transparent;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
}

.modal-content-Contract {
  background: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.2);
  padding: 1.25rem;
  width: 50%;
  position: relative;
  height: 75%;
}
.contract-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.contract-modal-content {
  width: 80vw;
  max-width: 800px;
  height: 70vh;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 200px;
}

.contract-close-button {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 1.5rem;
  color: #333;
  background: black;
  width: 28px;
  height: 28px;
  border: none;
  cursor: pointer;
}

.contract-iframe {
  flex-grow: 1;
  width: 100%;
  height: 50%;
  border: none;
  
}

.contract-viewer-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contract-viewer-box iframe {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
}

.close-button {
  width: 0.925rem;
  position: absolute;
  top: -0.4375rem;
  right: -0.625rem;
  background: transparent;
  border: none;
  font-size: 1.875rem;
  cursor: pointer;
  color: #333;
}

.milestone-card {
  position: relative;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
}

.popup-attached {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 513px;
height: 150px;
flex-shrink: 0;
  padding: 20px;
  border-radius: 0px 12px 12px 12px;
border: 1px solid rgba(0, 0, 0, 0.20);
background: #FFF;
box-shadow: 90px 417px 119px 0px rgba(0, 0, 0, 0.00), 58px 267px 109px 0px rgba(0, 0, 0, 0.01), 33px 150px 92px 0px rgba(0, 0, 0, 0.05), 14px 67px 68px 0px rgba(0, 0, 0, 0.09), 4px 17px 38px 0px rgba(0, 0, 0, 0.10);
  max-height: 500px;
  overflow-y: auto;
}

.popup-attached::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff; 
}

.close-popup {
  position: absolute;
  top: 12px;
  right: 12px;
  background: #ff5f5f;
  color: white;
  border: none;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  font-size: 16px;
  cursor: pointer;
}

.close-popup:hover {
  background: #ff1f1f;
}

.popup-title {
  color: var(--Text-h1, #18101C);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
  text-align: center; 
}


.popup-description {
  width: 390px;
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  white-space: normal; 
  text-align: justify; 
  margin-top: 10px; 
  margin-left: 44px;
}

.financial-terms-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
  margin-top: -10px;
}

.financial-terms-table th,
.financial-terms-table td {
  border: 1px solid #ddd;
  padding: 0.75rem;
  text-align: left;
}

.financial-terms-table th {
  color: var(--Text-h1, #18101C);
font-family: Outfit;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.financial-terms-table td {
  color: var(--Text-h1, #18101C);
font-family: Outfit;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.financial-terms-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.financial-terms-table tr:hover {
  background-color: #f1f1f1;
}
.legal-clauses-container {
  max-height: 200px; 
  overflow-y: auto; 
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fefefb;
}

.legal-clauses-list {
  display: flex;
  flex-direction: column; 
  gap: 8px; 
}

.legal-clause-item {
  margin: 0;
  padding: 8px 0; 
  border-bottom: 1px solid #eee; 
  color: var(--Text-h1, #18101C);
font-family: Outfit;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.legal-clause-item:last-child {
  border-bottom: none; 
}
.save-button {
  width: 100px;
  height: 32px;
  display: inline-flex;
padding: 6px 36px;
justify-content: center;
align-items: center;
gap: 10px;
  padding: 6px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 40px;
}

.save-button:hover {
  background-color: #0056b3;
}

.save-button:active {
  background-color: #004494;
}
.customer-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.party-label {
  white-space: nowrap; 
}

.editable-field {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  min-height: 1.5em;
  border-bottom: 1px dashed #007bff;
  padding: 5px;
  outline: none;
  box-sizing: border-box;
}

.popup-attached {
  position: absolute;
  z-index: 1000;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.milestone-section {
  position: relative; 
}
.popup-tag{
  margin-left: 200px;
}
.popup-header{
  display: flex;
}

.milestone-button{
  width: 98px;
height: 32px;
flex-shrink: 0;
border-radius: 4px;
background: var(--Secc, #8B55DD);
color: #FFF;
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.popup-close-contract img{
  margin-left: 105px;
  width: 24px;
  height: 24px;
  
}

.popup-date {
  width: 100px;
  color: var(--Text-h1, #18101C);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap; 
  
}
.mileStone-icon{
  margin-left: 102px;
}

.Strong-mile-date{
  color: var(--Text-h1, #18101C);
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-left: 19px;

}
.financial-terms-section,
.legal-clauses-section {
  margin-top: 1.25rem;
  width: 100%; 
}
.key-dates-invoice {
  margin-top: 20px;
  padding-top: 0.5rem;
  width: 100%;
  max-width: 2000px;
  height: 104px;
  margin-left: 25px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.05);
  border-radius: 0.75rem;
  box-sizing: border-box;
  overflow: hidden; 
}
.line-items-wrapper {
  
      align-items: center;
      gap: 0.75rem;
      border-radius: 6px;
      border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
      background: #FFF;
      width: 100%;
      max-width: 2000px;
      height: 280px;
      flex-shrink: 0;
      margin-top: 20px;
      margin-left: 25px;
      box-sizing: border-box; 
  
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .header-container{
    width: 100%;
    background: #fefaf7;
    
  }
  .contract-details {
    width: 100%;
    padding-left: 13.75rem;
    background: #fefaf7;
    margin-right: -27vw;
  }
  .header-content-invoice{
    width: 100%;
   
    margin-right: -27vw;
  }
  .key-dates-invoice{
    width: 100%;
   
    margin-right: -27vw;
  }
  .line-items-wrapper {
    width: 100%;
   
    margin-right: -27vw;
  }
  .key-dates{
    width: 95.2%;
    
  }
  .terms-and-conditions-section {
    flex-wrap: nowrap; 
  }

  .financial-terms-section,
  .legal-clauses-section {
    max-width: 100%;
  }
  .accordion-header-financial{
    display: flex;
  }
  .calendar-icon-contract {
    align-self: flex-start;
  }



}
@media (min-width: 1280px) and (max-width: 1440px) {
  .header-container{
    width: 100%;
    background: #fefaf7;
    
  }
  .contract-details {
    width: 100%;
    background: #fefaf7;
    /* margin-right: -4vw; */
  }
  .header-content-invoice{
    width: 100%;
    background: #fefaf7;
    
  }
  .key-dates-invoice{
    width: 100%;
    background: #fefaf7;
  }
  .line-items-wrapper {
    width: 100%;
    background: #fefaf7;
  }
  .key-dates{
    width: 95.2%;
  }
  .terms-and-conditions-section {
    flex-wrap: nowrap; 
  }

  .financial-terms-section,
  .legal-clauses-section {
    max-width: 100%; 
  }
  .accordion-header-financial{
    display: flex;
  }
  .calendar-icon-contract {
    align-self: flex-start; 
  }
  
}
@media (min-width: 1440px) and (max-width: 1536px) {
  .header-container{
    width: 100%;
    background: #fefaf7;
  }
  .contract-details {
    padding: 1.25rem;
    padding-left: 13.75rem;
    background: #fefaf7;
  }
  .header-content-invoice{
   
  }
  .key-dates-invoice{
    /* width: 100%;
    background: #fefaf7; */
  }
  .line-items-wrapper {
   
  }
  .key-dates{
    width: 95.4%;
  }
  .terms-and-conditions-section {
    flex-wrap: nowrap; 
  }

  .financial-terms-section,
  .legal-clauses-section {
    max-width: 100%; 
  }
  .accordion-header-financial{
    display: flex;
  }
  .calendar-iconcontract {
    align-self: flex-start; 
  }
}
@media (min-width: 1536px) and (max-width: 1920px) {
  .header-container{
    width: 100%;
    background: #fefaf7;
  }
  .contract-details {
    padding: 1.25rem;
    width: 100%;
    padding-left: 13.75rem;
    background: #fefaf7;
  }
  .header-content-invoice{
    
    width: 100%;
    
    
  }
  .line-items-wrapper {
    width: 98%;
    
    
  }
  .key-dates-invoice{
    width: 98.2%;
   
  }
  .key-dates{
    width: 95.4%;
  }
  .terms-and-conditions-section {
    flex-wrap: nowrap;
  }

  .financial-terms-section,
  .legal-clauses-section {
    max-width: 100%;
  }
  .accordion-header-financial{
    display: flex;
  }
  .calendar-icon-contract {
    align-self: flex-start; 
  }
  
}
@media (min-width: 1920px) and (max-width: 2560px) {
  .header-container{
    width: 100%;
    background: #fefaf7;
    box-sizing: border-box;
  }
  .contract-details {
    
    width: 98%;
    background: #fefaf7;
    margin-right: -20vw;
  }
  .header-content-invoice{
    width: 98%;
    
    margin-right: -20vw;
  }
  .key-dates-invoice{
    width: 98.5%;
    
    margin-right: -20vw;
  }
  .line-items-wrapper {
    width: 98%;
    
    margin-right: -20vw;
  }
  .key-dates{
    width: 97.2%;
  }
  .terms-and-conditions-section {
    width: 102%;
    flex-wrap: nowrap; 
  }

  .financial-terms-section,
  .legal-clauses-section {
    max-width: 110%; 
    width: 102%; 
  }
  .accordion-header-financial{
    display: flex;
  }
  .calendar-icon-contract {
    align-self: flex-start; 
  }
  .notes-section{
    width: 102%;
  }
  
} 

.unique {
  position: relative;
  display: inline-block;
}

.unique-input {
  width: 155px;
  height: 28px;
  border-radius: 6px;
  border: 0.5px solid rgba(0, 0, 0, 0.10);
  background: #fef9f7;
  outline: none;
  font-size: 15px;
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #73607b;
  padding-right: 30px; /* Space for the icon */
  padding-left: 8px;
}

.unique-calendar-icon {
  position: absolute;
  left: 124px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.custom-datepicker-popper {
  z-index: 1000; /* Ensure it appears above other elements */
}

.contract-duration {
  margin-bottom: 16px;
}

.label-terms {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 14px;
}

.contract-duration-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.contract-date {
  display: flex;
  flex-direction: column;
}

.date-label {
  color: var(--Neutral-1, #3F3F46);
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
  margin-bottom: 4px;
  margin-left: 35px;
}

.date-value {
  color: var(--Neutral-1, #3F3F46);
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
  margin-bottom: 4px;
  margin-left: 35px;
}

.contract-duration .date-input {
  width: 140px;
  height: 28px;
  border-radius: 6px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  background: #fef9f7;
  outline: none;
  padding-left: 6px;
  margin-left: 35px;
  color: var(--Text-h1, #18101C);
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.datepicker-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.date-input {
  width: 100%;
  padding: 10px 10px 10px 10px; /* Add padding for the calendar icon */
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background: #fef9f7;
}

.calendar-icon {
  position: absolute;
  right: 10px; /* Position inside the input field */
  cursor: pointer;
  width: 17px;
  height: 17px;
}

.custom-datepicker-popper {
  z-index: 1050 !important;
}

.custom-datepicker-popper {
  transform: scale(0.8); /* Adjust scale for desired size */
  transform-origin: top left; /* Maintain alignment */
}

.react-datepicker {
  font-size: 6px; /* Adjust font size */
}

.react-datepicker__day,
.react-datepicker__day-name {
  width: 1.0rem; /* Reduce day cell size */
  height: 1.0rem;
  line-height: 1.0rem;
}

.react-datepicker__current-month,
.react-datepicker__navigation {
  font-size: 10px; /* Smaller font for headers and navigation */
}
.react-datepicker__day--selected {
  padding-top: -6px;
  background-color: #C95EBE !important; /* Change the background color */
  color: #FFFFFF !important; /* Change the text color */
  border-radius: 50%; /* Optional: Make the selected day a circle */
  font-weight: bold; /* Optional: Make the text bold */
  border: 2px solid #FFFFFF; /* Optional: Add a white border */
  width: 2.5rem; /* Increase width */
  height: 1.5rem; /* Increase height */
  line-height: 1.5rem; /* Align text vertically */
  text-align: center; /* Center text horizontally */
}

.react-datepicker__day--selected:hover {
  background-color: #C95EBE !important; /* Change color on hover */
  color: #FFFFFF !important; /* Keep text white */
  border: 2px solid #C95EBE; /* Add hover effect border */
}

.react-datepicker__day {
  width: 2.5rem; /* Ensure all days have consistent width */
   /* Ensure all days have consistent height */
  line-height: 2.5rem; /* Ensure vertical alignment */
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.react-datepicker__day--keyboard-selected {
  background-color: #C95EBE !important;
  padding-top: -15px;
  height: 22px; /* Custom background color */
  color: white; /* Custom text color */
  border-radius: 2%; /* Make the selected day circular */
  border: 2px solid #C95EBE !important; /* Add a border around the selected day */
}





