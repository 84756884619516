@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap');


.AI-CP-modal-overlay-details {
    position: fixed;
    top: 60%;
    left: 55%;
    transform: translate(-50%, -50%); 
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    
    width: 790px; 
    max-width: 936px;
    height: 325px; 
    max-height: 411px; 
    overflow: hidden;
    background-color: white;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 90px 417px 119px 0px rgba(0, 0, 0, 0.00), 
                58px 267px 109px 0px rgba(0, 0, 0, 0.01), 
                33px 150px 92px 0px rgba(0, 0, 0, 0.05), 
                14px 67px 68px 0px rgba(0, 0, 0, 0.09), 
                4px 17px 38px 0px rgba(0, 0, 0, 0.10);
    flex-shrink: 0;
  }

.AI-CP-Full-Box{
    display: flex;
    align-items: center;
    justify-content: center;
    /* border:1px solid red; */
    flex-direction: column;
    height: auto;
}

  
  .modal-content1-details {
    background: #fff;
    padding: 20px 234px 63px 234px;
    border-radius: 16px;
    width: 718px;
    height: 161.43px;
    position: fixed;
    top: 94px;
    left: 36px;
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
    border: 2px solid red !important;
    text-align: center;
    overflow-y: auto;
    gap: 10px;
}

  
  
  .close-button {
    position: absolute;
    margin-top: 20px;
    top: -15px;
    right: -28px;
    border: none;
    background: transparent;
    font-size: 1.25rem;
    cursor: pointer;
    width: 40px;
  }
  
  .AI-CP-Heading1 {
    position: absolute;
    top: 22px; 
    left: 220px; 
    color: var(--Text-h2, #171717);
    font-family: Outfit;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  
  .AI-CP-Paragraph1 {
    position: absolute;
    top: 50px; 
    left: 230px; 
    color: var(--Neutral-200, #52525B);
    font-family: Outfit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  
  .AI-CP-upload-box {
    padding: 2.5rem;
    cursor: pointer;
    height: 161.43px;
    width: 718px;
    max-width: 52rem;
    /* margin: 0 auto; */
    text-align: center;
    margin-top: 94px;
    /* margin-left: 244px; */
    border-radius: 1rem;
    text-align: center;
    gap: 10px;
    border: 1px dashed var(--Secc, rgba(139, 85, 221, 1));
    background: rgba(245, 238, 255, 0.52);
    display: flex;
    align-items: initial;
    justify-content: center;
    flex-direction: column;
  }
  .AI-CP-uploading-box{
    padding: 2.5rem;
    cursor: pointer;
    height: 185.43px;
    /* height: 100%; */
    width: 718px;
    max-width: 52rem;
    /* margin: 0 auto; */
    text-align: center;
    margin-top: 54px;
    border-radius: 1rem;
    text-align: center;
    /* gap: 10px; */
    border: 1px dashed var(--Secc, rgba(139, 85, 221, 1));
    background: rgba(245, 238, 255, 0.52);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
    
  
  
  .choose-file {
    color: var(--Primari_300, #BB42AE);
    font-family: Outfit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }
  
  .uploading-status {
    background-color: #f9f4ff;
    padding: 1.25rem;
    border-radius: 0.5rem;
    margin-top: 1.25rem;
    width: 100%;
    max-width: 90%;
    text-align: center;
  }
  
  .upload-progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.25rem;
  }
  
  .progress-circle {
    background-color: #eaeaea;
    border-radius: 50%;
    width: 3.125rem;
    height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.625rem;
  }
  
  .progress-icon {
    font-size: 1.5rem;
    color: #6a6a6a;
  }
  
  .progress-text h3 {
    color: #333;
    font-size: 1.25rem;
    margin-bottom: 0.3125rem;
  }
  
  .progress-text p {
    color: #666;
    font-size: 0.9rem;
  }
  
  .AI-CP-progress-percentage-upload {
    /* color: var(--Primari_300, #BB42AE); */
    color: var(--Neutral_2, rgba(82, 82, 91, 1));

    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0.3375rem;
    margin-left: -25px;
    flex-wrap: nowrap; 
    width: 200px;
    text-overflow: ellipsis; 
  }
  
  .AI-CP-progress-bar-cont {
    width: 680px;
    background: #e0e0e0;
    height: 0.625rem;
    border-radius: 0.3125rem;
    overflow: hidden;
    margin-top: 0.625rem;
    margin-left: 11px;
    margin-bottom: -55px;
      }
  .progressText{
   display: flex;
   justify-content: center;
   align-items: end;
  }

  
  .AI-CP-progress-bar-fill {
    height: 100%;
    /* background-color: #a51d9b; */
    background: var(--Secc, rgba(139, 85, 221, 1));

    width: 0;
    transition: width 0.4s ease;
  }
  
  .AI-CP-time-estimate {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: #666;
    margin-top: -1.025rem;
    margin-left: 40rem;
  }
  
  .time-icon {
    margin-right: 0.3125rem;
  }
  
  .AI-CP-main-text {
    color: var(--Text-h2, #171717);
    font-family: Outfit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 60px;
  }
  
  .AI-CP-support {
    display: flex;
    color: var(--Neutral-150, #71717A);
    font-family: Outfit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 50px;
    margin-top: 1.25rem;
    white-space: nowrap;
  }
  
  .AI-CP-maximum {
    color: var(--Neutral-150, #71717A);
    font-family: Outfit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 42.5rem;
    margin-top: 1.25rem;
    white-space: nowrap;
  }
  
  .AI-CP-upload-icon {
    position: relative;
    display: inline-block;
  }
  
  .AI-CP-upload-big-icon {
    max-width: none;
    width: 5.375rem;
    height: 5.375rem;
    margin-left: 19rem;
  }
  
  .AI-CP-upload-small {
    max-width: none;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 22.2rem;
    top: 4rem;
    width: 1.575rem;
    height: 1.575rem;
  }
  
  .AI-CP-uploadingIconContainer {
    position: relative; /* Ensures child elements are positioned relative to this container */
    display: inline-block; /* Ensures container wraps tightly around the content */
    margin-top: -60px;
  }
  
  .AI-CP-uploadingIcon {
    /* border: 1px solid black; */
    margin: 0;
    /* margin-top: 42px; */
    width: 4.0625rem;
    height: 4.0625rem;
    flex-shrink: 0;
    display: block;
  }
  
  .AI-CP-uploadingIcon2 {
    position: absolute;
    bottom:12px; /* Aligns to the bottom of the first icon */
    right: 15px; /* Aligns to the right of the first icon */
    transform: translate(50%, 50%); /* Optional: Adjusts positioning to align better */
  }
  
  
  .AIsuccess-status {
    background: var(--Background-main, #FEF9F7);
    padding: 1.875rem;
    border-radius: 0.5rem;
    text-align: center;
    width: 100%;
    height: auto;
    max-width: 80.25rem;
    margin-left: 20px;
    margin-top: -30px;

  }
  
  .AI-CP-status-text-upload {
    color: var(--Neutral-500, #18181B);
    font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 18.9px;
    margin-top: -0.2rem;
    width: 421px;
    height: 19px;
    position: relative;
  
  }
  
   
  .success-icon-upload {
    background-color: rgba(139, 85, 221, 0.2);
    border-radius: 50%;
    width: 3.75rem;
    height: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1.25rem;
    backdrop-filter: blur(2.8758723735809326px)

  }
  
  .checkmark-icon {
    width: 1.875rem;
    height: 1.875rem;
  }
 .extractionBot-icon {
    width: 34px;
    height: 34px;

  }
  .AI-CP-extraction-container {
    display: flex;
    align-items: start; /* Vertically aligns items */
    justify-content:flex-start;  
    margin-top: 10px;
    /* gap: 10px; */
    /* border: 1px solid red; */
  }
  
  
 
  
  .extraction-link {
    color: #8b55dd; /* Matches the color theme */
    text-decoration: none; /* Removes underline from the link */
  }
  
  .extraction-link:hover {
    text-decoration: underline; /* Adds underline on hover */
  }
  
  
  .success-heading {
    color: var(--Neutral-500, #18181B);
    font-family: Outfit;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0.625rem;
    margin-left: 2.875rem;
  }
  
  .AI-CP-success-message-upload {
    color: var(--Neutral-300, #3F3F46);
    font-family: Outfit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 20px;
  }
  
  .AI-CP-file-info {
    display: flex;
    color: #333;
  
    margin-right: 200px;
  }
  
  .AI-CP-extraction-message {
    color: var(--Neutral-500, #18181B);
    font-family: Outfit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.1));
    border-radius: 12px;
    width: 585px;
    height: 72px;
    background: var(--Background-main, rgba(254, 249, 247, 1));

  }

  .AI-CP-extraction-message p:first-child {
    width: 464px;
    height: 18px;
    margin-top: 12px;
    margin-left: 34.23px;
    font-family: 'Outfit', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.64px;
  }
  
  .AI-CP-extraction-message p:last-child {
    font-family: 'Outfit', sans-serif;
    font-size: 14px;
    font-weight: 500; 
    color: var(--Neutral_2, rgba(82, 82, 91, 1)); /* Added background */
    width: 430px;
    height: 18px;
    margin-top: 12px;
    margin-left: 34.23px;
    font-family: 'Outfit', sans-serif;
     line-height: 17.64px;
  }

  .AI-CP-upload-box.drag-over {
    border: 2px dashed #8b55dd;
    background-color: rgba(139, 85, 221, 0.1);
  }
  

.AI-CP-left-arrow {
  display: inline-block;
  position: relative;
  border: 1px solid #777777;
  text-decoration: none;
  border-radius: 12px;
  background-color: white; /* Background color of the arrow body */
  margin-left: 20px;
}

.AI-CP-left-arrow:before {
  content: '';
  display: block;  
  position: absolute;
  top: 35%; /* Center vertically */
  left: -19px; /* Adjust position to align the arrow */
  transform: translateY(-50%); /* Center vertically */
  width: 0;
  height: 0;
  border: 10px solid transparent; /* Transparent base for arrow */
  border-right-color: #777777; /* Border color for arrow's outer edge */
}

.AI-CP-left-arrow:after {
  content: '';
  display: block;
  position: absolute;
  top: 35%;
  left: -16px; /* Slightly inside the outer arrow */
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border: 9px solid transparent; /* Transparent base for inner arrow */
  border-right-color: white; /* Inner color of the arrow */
}

  
  
  .AI-CP-button-container{
    display: flex;
    justify-content: center;
    align-items: center; 
    gap: 10px; 
    margin-top: 20px;
  }
  

  .AI-CP-summarizeDoc-button {
    
    color: #fff;
    padding: 10px;
    height: 44px;
    /* border: 1px solid black; */
    border: 1px solid #6F52ED;
    border-radius: 0.3125rem;
    color: rgba(111, 82, 237, 1);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: 0.01rem;
    cursor: pointer;
    /* margin-left: 320px; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap:8px;
  }

  
  .AI-CP-summarizeDoc-button:hover {
    /* background-color: #b31dcf; */
  }

  .summarizeNormalDoc-button{
   
    padding: 10px;
    height: 44px;
    /* border: 1px solid black; */
    border: 1px solid #6F52ED;
    border-radius: 0.3125rem;
    color:white;
    background: linear-gradient(90deg, #6F52ED 0%, #845EC2 100%);

    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: 0.01rem;
    cursor: pointer;
    /* margin-left: 320px; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap:8px;
  }

  .extract-details-button {
    background: linear-gradient(90deg, #6F52ED 0%, #845EC2 100%);
    height: 44px;

    color: var(--white-100, #EAF7FF);
    padding: 10px;
    border: none;
    border-radius: 0.3125rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: 0.01rem;
    cursor: pointer;
    /* margin-left: 320px; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap:8px;
  }
  
  
  .extract-details-button:hover {
    background-color: #b31dcf;
  }
  
.spreadsheet-button{
  background: linear-gradient(90deg, #6F52ED 0%, #845EC2 100%);
  height: 44px;

  color: var(--white-100, #EAF7FF);
  padding: 10px 32px;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0.01rem;
  cursor: pointer;
  /* margin-left: 320px; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap:8px;
}

.spreadsheet-button:hover{
  
}
  
  .uploadedMainContainer{
    /* border: 1px solid black; */
    width: 718px;
    display: flex;
    flex-direction: column;
    align-items: normal;   
   }
  .uploadedBottomContent{
    /* border: 1px solid black; */
    margin-top: 0px;
    margin-left:30px;
  }
  @media (min-width: 1024px) and (max-width: 1280px) {
    .body{
      font-size: 14px;
    }
    .upload-box {
      padding: 2.5rem;
      cursor: pointer;
      height: 23vh;
      width: 39.9rem;
      max-width: 52rem;
      text-align: center;
      margin-top: 1.25rem;
      margin-left: 17.15rem;
      border-radius: 1rem;
      border: 0.0625rem dashed var(--black_stroke, rgba(0, 0, 0, 0.1));
      background: var(--Background-main, #FEF9F7);
     
    }
    .upload-big-icon {
      max-width: none;
      width: 3.375rem;
      height: 3.375rem;
      margin-left: 15rem;
    }
    
    .upload-small {
      max-width: none;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 17.2rem;
      top: 2.2rem;
      width: 1.075rem;
      height: 1.075rem;
    }
    .support{
      margin-left: 110px;
    }
    .maximum{
      margin-left: 520px;
    }
    .main-text {
      color: var(--Text-h2, #171717);
      font-family: Outfit;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 2.9rem;
    }
    .uploadingIconContainer {
      position: relative; /* Ensures child elements are positioned relative to this container */
      display: inline-block; /* Ensures container wraps tightly around the content */
    }
    
    .uploadingIcon {
      /* border: 1px solid black; */
      margin: 0;
      margin-top: 42px;
    }
    
    .uploadingIcon2 {
      position: absolute;
      bottom:12px; /* Aligns to the bottom of the first icon */
      right: 15px; /* Aligns to the right of the first icon */
      transform: translate(50%, 50%); /* Optional: Adjusts positioning to align better */
    }
    
    .status-text-upload {
      color: var(--Neutral-500, #18181B);
      font-size: 1rem;
      margin-top: 0.1rem;
    }
  
    .progress-label {
      font-size: 0.6125rem;
      margin-right: 170rem;
    }
    .AI-CP-progress-percentage-upload {
      font-size: 14px;
      margin-top: 0.1375rem;
    
    }
    .AI-CP-time-estimate {
      font-size: 0.875rem;
      margin-top: -1.025rem;
      margin-left: 32rem;
    }
    .AI-CP-progress-bar-cont {
      width: 551px;
      margin-top: 0.125rem;
      margin-left: 11px;
     
    }
    .AIsuccess-status {
      background: var(--Background-main, #FEF9F7);
      padding: 1.875rem;
      border-radius: 0.5rem;
      text-align: center;
      width: 80%;
      height: auto;
      max-width: 80.25rem;
      margin-left: 20px;
      margin-top: -2px;
    }
    
    .success-icon-upload {
     
      width: 2.75rem;
      height: 2.75rem;
     
    }
  .success-heading {
  
    margin-top: -1.025rem;
    margin-left: 0.275rem;
    font-size: 14px;
  }
  .success-message-upload {
   
    font-size: 0.875rem;
    
    margin-left: 20px;
  }
  /* .file-info {
    display: flex;
    color: #333;
  
    margin-right: 320px;
  } */
  
  }
  @media (min-width: 1280px) and (max-width: 1440px) {
    .body{
      font-size: 14px;
    }
    .upload-box {
      padding: 2.5rem;
      cursor: pointer;
      height: 23vh;
      width: 43.9rem;
      max-width: 52rem;
      text-align: center;
      margin-top: 1.25rem;
      margin-left: 13.15rem;
      border-radius: 1rem;
      border: 0.0625rem dashed var(--black_stroke, rgba(0, 0, 0, 0.1));
      background: var(--Background-main, #FEF9F7);
     
    }
  
    .upload-big-icon {
      max-width: none;
      width: 3.375rem;
      height: 3.375rem;
      margin-left: 15rem;
    }
    
    .upload-small {
      max-width: none;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 17.2rem;
      top: 2.2rem;
      width: 1.075rem;
      height: 1.075rem;
    }
    .support{
      margin-left: 50px;
    }
    .maximum{
      margin-left: 530px;
    }
    .main-text {
      color: var(--Text-h2, #171717);
      font-family: Outfit;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 2.9rem;
    }
    .uploadingIconContainer {
      position: relative; /* Ensures child elements are positioned relative to this container */
      display: inline-block; 
    }
    
    .uploadingIcon {
      /* border: 1px solid black; */
      margin: 0;
      margin-top: 42px;
    }
    
    .uploadingIcon2 {
      position: absolute;
      bottom:12px; /* Aligns to the bottom of the first icon */
      right: 15px; /* Aligns to the right of the first icon */
      transform: translate(50%, 50%); /* Optional: Adjusts positioning to align better */
    }
    
    .status-text-upload {
      color: var(--Neutral-500, #18181B);
      font-size: 1rem;
      margin-top: 0.1rem;
    }
    .sub-status-text {
      font-size: 0.875rem;
      margin-top: 0.1rem;
    }
    .progress-label {
      font-size: 0.6125rem;
      margin-right: 170rem;
    }
    .progress-percentage-upload {
      font-size: 14px;
      margin-top: 0.1375rem;
    
    }
    .time-estimate {
      font-size: 0.875rem;
      margin-top: -1.025rem;
      margin-left: 32rem;
    }
    .progress-bar-cont {
      width: 551px;
      margin-top: 0.125rem;
      margin-left: 11px;
    }
    .AIsuccess-status {
      background: var(--Background-main, #FEF9F7);
      padding: 1.875rem;
      border-radius: 0.5rem;
      text-align: center;
      width: 70%;
      height: 29vh;
      max-width: 80.25rem;
      margin-left: 70px;
    }
     
    .success-icon-upload {
     
      width: 2.75rem;
      height: 2.75rem;
     
    }
  .success-heading {
  
    margin-top: -1.025rem;
    margin-left: 0.275rem;
    font-size: 14px;
  }
  .success-message-upload {
   
    font-size: 0.875rem;
    
    margin-left: 20px;
  }
  .file-info {
    display: flex;
    color: #333;
  
    margin-right: 320px;
  }
  
  }