.modal-overlay-details {
  position: fixed;
  top: 60%;
  left: 60%;
  transform: translate(-50%, -50%); 
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  
  width: 58.75vw; 
  max-width: 936px;
  height: 55.06vh; 
  max-height: 411px; 
  overflow: hidden;
  background-color: white;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 90px 417px 119px 0px rgba(0, 0, 0, 0.00), 
              58px 267px 109px 0px rgba(0, 0, 0, 0.01), 
              33px 150px 92px 0px rgba(0, 0, 0, 0.05), 
              14px 67px 68px 0px rgba(0, 0, 0, 0.09), 
              4px 17px 38px 0px rgba(0, 0, 0, 0.10);
  flex-shrink: 0;
}
.upload-box.drag-over {
  border: 2px dashed #8a2be2;
  background-color: #f0f0f0;
}

.hidden-modal {
  display: none;
}

.modal-content1-details {
  background: #fff;
  padding: 1.25rem;
  border-radius: 0.5rem;
  width: 25%;
  max-width: 55.375rem;
  height: 55rem;
  max-height: 90vh;
  overflow-y: auto;
  text-align: center;
  position: relative;
  margin-left: 11.25rem;
  margin-top: 6.25rem;
}


.close-button {
  position: absolute;
  margin-top: 20px;
  top: 0.625rem;
  right: 0.625rem;
  border: none;
  background: transparent;
  font-size: 1.25rem;
  cursor: pointer;
  width: 40px;
}

.Heading1 {
  position: absolute;
  top: 22px; 
  left: 43px; 
  color: var(--Text-h2, #171717);
  font-family: Outfit;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


.Paragraph1 {
  position: absolute;
  top: 50px; 
  left: 43px; 
  color: var(--Neutral-200, #52525B);
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.upload-box {
  padding: 2.5rem;
  cursor: pointer;
  height: 28vh;
  width: 50.25rem;
  max-width: 52rem;
  margin: 0 auto;
  text-align: center;
  margin-top: 1.25rem;
  margin-left: 14.55rem;
  border-radius: 1rem;
  border: 0.0625rem dashed var(--black_stroke, rgba(0, 0, 0, 0.1));
  background: var(--Background-main, #FEF9F7);
 
}




.choose-file {
  color: var(--Primari_300, #BB42AE);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.uploading-status {
  background-color: #f9f4ff;
  padding: 1.25rem;
  border-radius: 0.5rem;
  margin-top: 1.25rem;
  width: 100%;
  max-width: 90%;
  text-align: center;
}

.upload-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.25rem;
}

.progress-circle {
  background-color: #eaeaea;
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.625rem;
}

.progress-icon {
  font-size: 1.5rem;
  color: #6a6a6a;
}

.progress-text h3 {
  color: #333;
  font-size: 1.25rem;
  margin-bottom: 0.3125rem;
}

.progress-text p {
  color: #666;
  font-size: 0.9rem;
}

.progress-percentage-upload {
  color: var(--Primari_300, #BB42AE);
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0.3375rem;
 margin-left: -2rem;
  flex-wrap: nowrap; 
 width: 200px;
  text-overflow: ellipsis; 
}

.progress-bar-cont {
  width: 711px;
  background: #e0e0e0;
  height: 0.625rem;
  border-radius: 0.3125rem;
  overflow: hidden;
  margin-top: 0.125rem;
  margin-left: 11px;
}

.progress-label {
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 150rem;
  flex-wrap: nowrap; 
  width: 100px;
   text-overflow: ellipsis; 
}

.progress-bar-fill {
  height: 100%;
  background-color: #a51d9b;
  width: 0;
  transition: width 0.4s ease;
}

.time-estimate {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #666;
  margin-top: -1.025rem;
  margin-left: 42rem;
}

.time-icon {
  margin-right: 0.3125rem;
}

.main-text {
  color: var(--Text-h2, #171717);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 6rem;
}

.support {
  display: flex;
  color: var(--Neutral-150, #71717A);
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 50px;
  margin-top: 1.25rem;
  white-space: nowrap;
}

.maximum {
  color: var(--Neutral-150, #71717A);
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 42.5rem;
  margin-top: 1.25rem;
  white-space: nowrap;
}

.upload-icon {
  position: relative;
  display: inline-block;
}

.upload-big-icon {
  max-width: none;
  width: 5.375rem;
  height: 5.375rem;
  margin-left: 20rem;
}

.upload-small {
  max-width: none;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 23.2rem;
  top: 4rem;
  width: 1.575rem;
  height: 1.575rem;
}

.uploadingIcon {
  width: 4.0625rem;
  height: 4.0625rem;
  flex-shrink: 0;
  margin-left: 20rem;
  margin-top: -2rem;
  display: block;
}

.success-status {
  background: var(--Background-main, #FEF9F7);
  padding: 1.875rem;
  border-radius: 0.5rem;
  text-align: center;
  width: 80%;
  max-width: 80.25rem;
  height: auto;
  box-sizing: border-box;
  margin: 0 auto;
  position: absolute;
  top: 40%;
  right: 10%;
  transform: translateY(-50%);
}


.status-text-upload {
  color: var(--Neutral-500, #18181B);
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0.5rem;
}

.sub-status-text {
  color: var(--Neutral-300, #3F3F46);
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0.5rem;
}

.success-icon-upload {
  background-color: #fde8ff;
  border-radius: 50%;
  width: 3.75rem;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.25rem;
  margin-top: 10px;
}

.checkmark-icon {
  width: 1.875rem;
  height: 1.875rem;
}

.success-heading {
  color: var(--Neutral-500, #18181B);
  font-family: Outfit;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.625rem;
  margin-left: 2.875rem;
}

.success-message-upload {
  color: var(--Neutral-300, #3F3F46);
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 20px;
}

.file-info {
  display: flex;
  color: #333;

  margin-right: 200px;
}

.file-name-icon {
  margin-right: 0.5rem;
  font-size: 1.2rem;
  margin-left: 14.625rem;
}

.file-name {
  color: var(--Neutral-300, #3F3F46);
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.extraction-message {
  position: absolute;
  top: 71%;
  left: 60%;
  transform: translateX(-50%);
  color: var(--Neutral-500, #18181B);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  width: 600px;
}


.extraction-link {
  color: var(--Secc, #8B55DD);
  font-family: Outfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}

.extraction-link:hover {
  text-decoration: underline;
}

.view-details-button {
  position: absolute;
  top: 80%; 
  left: 52%; 
  transform: translateX(-50%); 
  background: var(--Primary-500, #C95EBE);
  color: var(--white-100, #EAF7FF);
  padding: 0.625rem 1.25rem;
  border: none;
  border-radius: 0.3125rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0.01rem;
  cursor: pointer;
}

.view-details-button-doc {
  background: var(--Primary-500, #C95EBE);
  color: var(--white-100, #EAF7FF);
  padding: 0.625rem 2.25rem;
  border: none;
  border-radius: 0.3125rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0.01rem;
  cursor: pointer;
  margin-top: 250px;

  /* Flexbox to center the button */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.view-details-button:hover {
  background-color: #b31dcf;
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .body{
    font-size: 14px;
  }
  .upload-box {
    padding: 2.5rem;
    cursor: pointer;
    height: 23vh;
    width: 39.9rem;
    max-width: 52rem;
    text-align: center;
    margin-top: 1.25rem;
    margin-left: 17.15rem;
    border-radius: 1rem;
    border: 0.0625rem dashed var(--black_stroke, rgba(0, 0, 0, 0.1));
    background: var(--Background-main, #FEF9F7);
   
  }
  .upload-big-icon {
    max-width: none;
    width: 3.375rem;
    height: 3.375rem;
    margin-left: 15rem;
  }
  
  .upload-small {
    max-width: none;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 17.2rem;
    top: 2.2rem;
    width: 1.075rem;
    height: 1.075rem;
  }
  .support{
    margin-left: 110px;
  }
  .maximum{
    margin-left: 520px;
  }
  .main-text {
    color: var(--Text-h2, #171717);
    font-family: Outfit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 2.9rem;
  }
  .uploadingIcon {
    width: 2.0625rem;
    height: 2.0625rem;
    flex-shrink: 0;
    margin-left: 16rem;
    margin-top: -2rem;
    display: block;
  }
  .status-text-upload {
    color: var(--Neutral-500, #18181B);
    font-size: 1rem;
    margin-top: 0.1rem;
  }
  .sub-status-text {
    font-size: 0.875rem;
    margin-top: 0.1rem;
  }
  .progress-label {
    font-size: 0.6125rem;
    margin-right: 170rem;
  }
  .progress-percentage-upload {
    font-size: 14px;
    margin-top: 0.1375rem;
  
  }
  .time-estimate {
    font-size: 0.875rem;
    margin-top: -1.025rem;
    margin-left: 32rem;
  }
  .progress-bar-cont {
    width: 551px;
    margin-top: 0.125rem;
    margin-left: 11px;
  }
  

  
  .success-icon-upload {
   
    width: 2.75rem;
    height: 2.75rem;
   
  }
.success-heading {

  margin-top: -1.025rem;
  margin-left: 0.275rem;
  font-size: 14px;
}
.success-message-upload {
 
  font-size: 0.875rem;
  
  margin-left: 20px;
}
.file-info {
  display: flex;
  color: #333;

  margin-right: 320px;
}

}
@media (min-width: 1280px) and (max-width: 1440px) {
  .body{
    font-size: 14px;
  }
  .upload-box {
    padding: 2.5rem;
    cursor: pointer;
    height: 23vh;
    width: 43.9rem;
    max-width: 52rem;
    text-align: center;
    margin-top: 1.25rem;
    margin-left: 13.15rem;
    border-radius: 1rem;
    border: 0.0625rem dashed var(--black_stroke, rgba(0, 0, 0, 0.1));
    background: var(--Background-main, #FEF9F7);
   
  }
  .upload-big-icon {
    max-width: none;
    width: 3.375rem;
    height: 3.375rem;
    margin-left: 15rem;
  }
  
  .upload-small {
    max-width: none;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 17.2rem;
    top: 2.2rem;
    width: 1.075rem;
    height: 1.075rem;
  }
  .support{
    margin-left: 50px;
  }
  .maximum{
    margin-left: 530px;
  }
  .main-text {
    color: var(--Text-h2, #171717);
    font-family: Outfit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 2.9rem;
  }
  .uploadingIcon {
    width: 2.0625rem;
    height: 2.0625rem;
    flex-shrink: 0;
    margin-left: 16rem;
    margin-top: -2rem;
    display: block;
  }
  .status-text-upload {
    color: var(--Neutral-500, #18181B);
    font-size: 1rem;
    margin-top: 0.1rem;
  }
  .sub-status-text {
    font-size: 0.875rem;
    margin-top: 0.1rem;
  }
  .progress-label {
    font-size: 0.6125rem;
    margin-right: 170rem;
  }
  .progress-percentage-upload {
    font-size: 14px;
    margin-top: 0.1375rem;
  
  }
  .time-estimate {
    font-size: 0.875rem;
    margin-top: -1.025rem;
    margin-left: 32rem;
  }
  .progress-bar-cont {
    width: 551px;
    margin-top: 0.125rem;
    margin-left: 11px;
  }
 
   
  .success-icon-upload {
   
    width: 2.75rem;
    height: 2.75rem;
   
  }
.success-heading {

  margin-top: -1.025rem;
  margin-left: 0.275rem;
  font-size: 14px;
}
.success-message-upload {
 
  font-size: 0.875rem;
  
  margin-left: 20px;
}
.file-info {
  display: flex;
  color: #333;

  margin-right: 320px;
}

}