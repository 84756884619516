.cancel-subs-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Dim background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .cancel-subs-billing {
    width: 375px;
    height: 226px;
    border-radius: 12px;
    background: #FFF;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
  }
.cancel-subs-billing-title{
    color: var(--Text-h1, #18101C);

font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}  
.cancel-subs-billing-subs{
    color: var(--reeedd, rgba(226, 25, 25, 0.75));

font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.cancel-subs-billing-substwo{
    color: var(--text_non_active, #73607B);
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
width: 327px;
}
.cancel-subs-billing-button{
    color: var(--Primary_400, #A04A97);
text-align: center;
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
display: flex;
padding: 8px 12px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 9px;
border: 1px solid var(--text_non_active, #73607B);
}
.cancel-subs-billing-buttontwo{
    display: flex;
padding: 8px 12px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 9px;
background: var(--Primary-500, #C95EBE);
color: var(--White, #F5F4F3);
text-align: center;
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
}