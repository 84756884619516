.custom-popup-overlay-month {
    position: fixed;
    top: 0;
    left: 0;
   width: 100%;
   height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: flex-start; /* Aligns content to the left */
    align-items: center;
    padding-left: 487px;
    padding-top: 100px; /* Adjust left spacing without creating extra space */
    z-index: 1000;
}
.popup-content-month {
    background: white;
    padding: 15px;
   
    flex-shrink: 0;
    width: 746px;
    border-radius: 12px;
background: #FFF;
    position: relative;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    
  
flex-shrink: 0;
}
.amount-button-billing{
    display: flex;
padding: 12px 83px 12px 75px;
align-items: center;
align-self: stretch;
border-radius: 24px;
border: 1px solid var(--Secc, #8B55DD);
background: var(--Secc, #8B55DD);
color: var(--White, #F5F4F3);
text-align: center;
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.custom-popup-title-month{
    color: var(--Text-h1, #18101C);
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
align-self: stretch;
}