.document-full-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: "Outfit", sans-serif;
  }
  .document-contract-table-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
  }
  .document-Input-space {
    color: var(--Neutral-150, #71717A);
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .document-contract-table-header {
    display: flex;
    justify-content: space-between;
    padding-right: 36px;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
  }
  .document-search-filter-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .document-search-box {
    width: 330px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    display: inline-flex;
    padding: 8px 113px 8px 20px;
    align-items: center;
    gap: 12px;
    margin-top: 33px;
    margin-left: 210px;
    font-family: Outfit;
    font-size: 14px;
  }
  .document-search-box input {
    width: 191px;
    height: 18px;
    color: var(--Neutral-150, #71717A);
    font-family: Outfit;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-left: 5px;
    border: none;
    outline: none;
  }
  .document-filter-button-folder {
    width: 147px;
    height: 61px;
    background: transparent;
    border: none;
    margin-top: 33px;
    cursor: pointer;
    
  }
  .document-upload-section {
    display: flex;
    gap: 20px; 
    align-items: center; 
    margin-top: 33px;
  }
  .document-upload-button1 {
    width: 202px;
    height: 45px;
    display: inline-flex;
    padding: 12px 20px;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid var(--Primary-500, #C95EBE);
    color: var(--Primary-500, #C95EBE);
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: var(--Typography-Line-Height-300, 24px); 
    letter-spacing: 0.16px;
    cursor: pointer;
  }
  .document-upload-button2 {
    width: 152px;
    height: 45px;
    display: inline-flex;
padding: 8px 16px;
justify-content: center;
align-items: center;
gap: 8px;
    color: var(--White, #F5F4F3);
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: var(--Typography-Line-Height-300, 24px); 
letter-spacing: 0.14px;
    
    cursor: pointer;
    margin-left: 10px;
    border-radius: 6px;
border: 1px solid var(--Primary-500, #C95EBE);
background: var(--Primary-500, #C95EBE);
  }
  
    
  
.preview-button {
    display: inline-flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid var(--Primary-500, #C95EBE);
    background-color: transparent;
    cursor: pointer;
  }
  
  .preview-button img {
    width: 24px;
    height: 24px;
  }
  
  .preview-button span {
    color: var(--Primary-500, #C95EBE);
    font-family: 'Outfit', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: var(--Typography-Line-Height-300, 24px);
    letter-spacing: 0.14px;
  }
  .document-custom-checkbox {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
    appearance: none;
    cursor: pointer;
    position: relative;
  }
  
  .document-custom-checkbox:checked::after {
    content: "✓";
    color: "#52525B";
    font-size: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .document-container {
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    margin-top: 8px;
    margin-right: 100px;
    flex-grow: 1;
    width: 85%;
    max-width: 100%;
    margin-left: 200px;
    max-height: calc(100vh - 12.5rem);
    box-sizing: border-box;
    padding-right: 10px;
    overflow-y: auto;
    overflow-x: auto;
  }
  
  
  .document-inner-wrapper {
    padding-right: 10px;
    box-sizing: border-box;
  }
  
  .document-container::-webkit-scrollbar {
    width: 9px;
    flex-shrink: 0;
    border-radius: 18px;
  }

  
  
  .document-container::-webkit-scrollbar-track {
    border-radius: 18px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #DBDBDB;
    margin-right: 10px;
    margin-top: 60px;
    margin-bottom: 50px;
  }
  .document-action-menu.folder-options {
    height: 102px; 
  }
  .document-action-menu-underfolder {
    display: flex;
    flex-direction: column;
    position: absolute;
    border-radius: 12px 0px 12px 12px;
    border: 1px solid var(--Stroke-black, rgba(0, 0, 0, 0.10));
    background: #FFF;
    padding: 10px;
    width: 198px;
    height: 106px;
    flex-shrink: 0;
    z-index: 1000;
    margin-left: 37px;
    margin-top: -4px;
    transform: translateX(-110%);
  }

  .document-action-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    border-radius: 12px 0px 12px 12px;
    border: 1px solid var(--Stroke-black, rgba(0, 0, 0, 0.10));
    background: #FFF;
    padding: 10px;
    width: 198px;
    height: 144px;
    flex-shrink: 0;
    z-index: 1000;
    margin-left: 37px;
    margin-top: -4px;
    transform: translateX(-110%);
  }
  
  .document-action-item {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 18px;
    color: var(--Neutral-150, #71717A);
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #666;
    row-gap: 20px;
  }
  
  .document-action-item:hover {
    background-color: #f0f0f0;
  }
  
  .document-action-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  
  
  .document-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  
  .document-container::-webkit-scrollbar-thumb:hover {
    background: #FAF9F6;
  }
  
  .document-contract-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .document-contract-table thead {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
    color: var(--text_non_active, #73607B);
    line-height: normal;
  }

tbody  tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
}

thead  tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
}


tbody tr:hover {
  background: var(--Background-main, #FEF9F7);  
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25); 
}

  .document-contract-table td {
    padding: 19.5px 13px;
    text-align: left;
    color: var(--Text-h2, #171717);
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .document-contract-table th {
    padding: 12px;
    text-align: left;
    color: var(--text_non_active, #73607B);
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .document-pagination {
    position: absolute; 
    bottom: 0; 
    left: 201;
    width: 81.8vw; 
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 10px 0;
    padding-left: 20px;
    border-radius: 6px;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1); 
    z-index: 10; 
}



.document-pagination button {
    display: flex;
    width: 20px;
    height: 20px;
    transform: rotate(-180deg);
    padding: 5.333px 3.333px;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.document-contentDown {
    color: var(--One-Liquidity-Text-Black-50, #666);
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 10px;
}

.document-page-selector {
    color: var(--Black-30, #8B8D97);
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    height: 27px;
    padding: 4px 3px;
    padding-left: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: rgba(94, 99, 102, 0.08);
}

.document-lower-part {
    margin-left: 30px;
    border: none;
}

.document-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}
.document-modal-overlay-details {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background-color: white;
    width: 1086px;
    height: 481px;
    flex-shrink: 0;
    margin-top: 180px;
    margin-left: 340px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #FFF;
    box-shadow: 90px 417px 119px 0px rgba(0, 0, 0, 0.00), 58px 267px 109px 0px rgba(0, 0, 0, 0.01), 33px 150px 92px 0px rgba(0, 0, 0, 0.05), 14px 67px 68px 0px rgba(0, 0, 0, 0.09), 4px 17px 38px 0px rgba(0, 0, 0, 0.10);
  }
  
  .document-modal-content1-details {
    background: #fff;
    padding: 1.25rem;
    border-radius: 0.5rem;
    width: 25%;
    max-width: 55.375rem;
    height: 55rem;
    max-height: 90vh;
    overflow-y: auto;
    text-align: center;
    position: relative;
    margin-left: 11.25rem;
    margin-top: 6.25rem;
  }
  
  .document-close-button {
    position: absolute;
    margin-top: 20px;
    top: 0.625rem;
    right: 0.625rem;
    border: none;
    background: transparent;
    font-size: 1.25rem;
    cursor: pointer;
    width: 40px;
  }
  
  .document-heading1 {
    color: var(--Text-h2, #171717);
    font-family: Outfit;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-right: 48.125rem;
  }
  
  .document-paragraph1 {
    color: var(--Neutral-200, #52525B);
    font-family: Outfit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 1.25rem;
    margin-right: 40.3125rem;
  }
  
  .document-upload-box {
    padding: 2.5rem;
    cursor: pointer;
    width: 100%;
    max-width: 60.25rem;
    margin: 0 auto;
    text-align: center;
    margin-top: 1.25rem;
    margin-left: 1.25rem;
    border-radius: 1rem;
    border: 0.0625rem dashed var(--black_stroke, rgba(0, 0, 0, 0.1));
    background: var(--Background-main, #FEF9F7);
  }
  
  .document-upload-box p {
    margin: 0.625rem 0;
    font-size: 1rem;
    color: #333;
  }
  
  .document-choose-file {
    color: var(--Primari_300, #BB42AE);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }
  
  .document-uploading-status {
    background-color: #f9f4ff;
    padding: 1.25rem;
    border-radius: 0.5rem;
    margin-top: 1.25rem;
    width: 100%;
    max-width: 90%;
    text-align: center;
  }
  
  .document-upload-progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.25rem;
  }
  
  .document-progress-circle {
    background-color: #eaeaea;
    border-radius: 50%;
    width: 3.125rem;
    height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.625rem;
  }
  
  .document-progress-icon {
    font-size: 1.5rem;
    color: #6a6a6a;
  }
  
  .document-progress-text h3 {
    color: #333;
    font-size: 1.25rem;
    margin-bottom: 0.3125rem;
  }
  
  .document-progress-text p {
    color: #666;
    font-size: 0.9rem;
  }
  
  .document-progress-percentage {
    font-size: 1.125rem;
    color: #a51d9b;
    margin-top: 0.9375rem;
  }
  
  .document-progress-bar {
    width: 100%;
    background: #e0e0e0;
    height: 0.625rem;
    border-radius: 0.3125rem;
    overflow: hidden;
    margin-top: 0.625rem;
  }
  
  .document-progress-label {
    color: var(--text_non_active, #73607B);
    font-family: Inter;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 50rem;
  }
  
  .document-progress-bar-fill {
    height: 100%;
    background-color: #a51d9b;
    width: 0;
    transition: width 0.4s ease;
  }
  
  .document-time-estimate {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: #666;
    margin-top: 0.625rem;
  }
  
  .document-time-icon {
    margin-right: 0.3125rem;
  }
  
  .document-main-text {
    color: var(--Text-h2, #171717);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .document-support {
    color: var(--Neutral-150, #71717A);
    font-family: Outfit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 1.25rem;
    margin-top: 1.25rem;
  }
  
  .document-maximum {
    color: var(--Neutral-150, #71717A);
    font-family: Outfit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 42.5rem;
    margin-top: 1.25rem;
  }
  
  .document-upload-icon {
    position: relative;
    display: inline-block;
  }
  
  .document-upload-big {
    width: 4.375rem;
    height: 4.375rem;
  }
  
  .document-upload-small {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 1.875rem;
    height: 1.875rem;
  }
  
  .document-uploading-icon {
    width: 4.0625rem;
    height: 4.0625rem;
    flex-shrink: 0;
    margin: -0.625rem auto 0;
    display: block;
  }
  
  .document-success-status {
    background-color: #f9f4ff;
    padding: 1.875rem;
    border-radius: 0.5rem;
    text-align: center;
    width: 100%;
    max-width: 56.25rem;
    margin: 0 auto;
  }
  
  .document-status-text {
    color: var(--Neutral-500, #18181B);
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .document-sub-status-text {
    color: var(--Neutral-300, #3F3F46);
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .document-success-icon {
    background-color: #fde8ff;
    border-radius: 50%;
    width: 3.75rem;
    height: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1.25rem;
  }
  
  .document-checkmark-icon {
    width: 1.875rem;
    height: 1.875rem;
  }
  
  .document-success-heading {
    color: var(--Neutral-500, #18181B);
    font-family: Outfit;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0.625rem;
    margin-left: 2.875rem;
  }
  
  .document-success-message {
    color: var(--Neutral-300, #3F3F46);
    font-family: Outfit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 2.25rem;
    margin-left: 20px;
  }
  
  .document-file-info {
    display: flex;
    color: #333;
    margin-bottom: 1.25rem;
  }
  
  .document-file-name-icon {
    margin-right: 0.5rem;
    font-size: 1.2rem;
    margin-left: 20.625rem;
  }
  
  .document-file-name {
    color: var(--Neutral-300, #3F3F46);
    font-family: Outfit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .document-extraction-message {
    color: var(--Neutral-500, #18181B);
    font-family: Outfit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1.25rem;
  }
  
  .document-extraction-link {
    color: var(--Secc, #8B55DD);
    font-family: Outfit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
  }
  
  .document-extraction-link:hover {
    text-decoration: underline;
  }
  
  .document-view-details-button {
    background-color: #d823f7;
    color: #fff;
    padding: 0.625rem 1.25rem;
    border: none;
    border-radius: 0.3125rem;
    color: var(--white-100, #EAF7FF);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: 0.01rem;
    cursor: pointer;
  }
  
  .document-view-details-button:hover {
    background-color: #b31dcf;
  }
  .file-name-cell {
    display: flex;
    align-items: center;
  }
  
  .file-icon-and-name {
    display: flex;
    align-items: center;
  }
  
  .file-icon {
    width: 24px; 
    height: 24px;
    margin-right: 8px; 
  }
  
  .file-details {
    display: flex;
    flex-direction: column;
  }
  
  .file-name {
    font-weight: bold;
    font-size: 16px; 
    color: #333; 
  }
  
  .file-type {
    font-size: 12px;
    color: #666;
  }
  .chat-button-cell {
    text-align: center;
  }
  
  .chat-button-doc {
    width: 120px;
    height: 37px;
    display: inline-flex;
    padding: 6px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    /* border: 1px solid var(--Secc, #8B55DD); */
    color: #A04A97;
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative; /* Ensures proper positioning for the pseudo-element */
  }
  
 
  
  .chat-button-doc:hover::after {
    content: "Click to Chat"; /* Text to display on hover */
    position: absolute;
    bottom: 25px; /* Adjust position below the button */
    left: 50%;
    transform: translateX(-50%);
    background-color: #f4e1fc;
    color: #A04A97;
    padding: 4px 6px;
    border-radius: 4px;
    font-size: 14px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  
  .chat-icon {
    width: 20px; 
    height: 20px;
    margin-right: 8px; 
  }
  .chat-button-doc span{
    color: var(--Secc, #8B55DD);
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;  
  }

  .document-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .document-popup-box {
    width: 400px;
    background: white;
    padding: 30px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .document-popup-box h2 {
    color: var(--Neutral-500, #18181B);
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
  }
  
  .document-popup-box p {
    color: var(--Neutral-150, #71717A);
    text-align: center;
    font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 20px;
  }
  
  .document-popup-close-button {
    display: flex;
    padding: 8px 36px;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    border-radius: 8px;
    background: var(--Primary-500, #C95EBE); 
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    margin-left: 112px;
  }
  .document-popup-close-delete {
    display: flex;
    padding: 8px 36px;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    border-radius: 8px;
    background: var(--Primary-500, #C95EBE);
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    margin-left: 1px;
  }
  
  .document-popup-close-button-delete {
    display: flex;
    padding: 18px 36px;
    justify-content: center;
    align-items: center;
    gap: 30px;
    border-radius: 8px;
    background: var(--Primary-500, #C95EBE); 
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
  }
  
  .document-save-design {
    display: flex;
    padding: 8px 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--Primary-500, #C95EBE);
    color: white;
    cursor: pointer;
  }
.document-popup-box-delete{
  width: 526px;
height: 172px;
flex-shrink: 0;
border-radius: 12px;
border: 1px solid rgba(0, 0, 0, 0.20);
background: #FFF;
box-shadow: 90px 417px 119px 0px rgba(0, 0, 0, 0.00), 58px 267px 109px 0px rgba(0, 0, 0, 0.01), 33px 150px 92px 0px rgba(0, 0, 0, 0.05), 14px 67px 68px 0px rgba(0, 0, 0, 0.09), 4px 17px 38px 0px rgba(0, 0, 0, 0.10);
}  

.document-popup-box-delete h2{
  color: var(--Text-h2, #171717);
font-family: Outfit;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left: 170px;
margin-top: 10px;
}

.document-popup-box-delete p{
  color: var(--Neutral-150, #71717A);
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 20px;
  margin-left: 20px;
}


.pdf-viewer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease; 
}


.pdf-viewer-container {
  position: relative;
  width: 856px; 
  height: 517px;
  background: #fff; 
  border-radius: 12px; 
  overflow: hidden;
  animation: slideIn 0.3s ease;
  flex-shrink: 0; 
  margin-right: 1000px;
  margin-top: 100px;
}

.close-viewer-button {
  position: absolute;
  top: 14px;
  right: 10px;
  background: black; 
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.close-viewer-button:hover {
  transform: scale(1.1);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3); 
  background: #d9363e; 
}


.pdf-viewer-iframe {
  width: 100%;
  height: 100%;
  border: none;
  background: #f9f9f9; 
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .pdf-viewer-container {
    width: 95%; 
    height: 95%;
  }

  .close-viewer-button {
    width: 36px;
    height: 36px;
    font-size: 18px;
  }
}

.document-button-cat {
  position: absolute;
  right: 30px;
  top: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.document-button-cat button {
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 28px;
  border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
  background: var(--sec_shade, rgba(139, 85, 221, 0.16));
  cursor: pointer;
  transition: background-color 0.2s;
}
.document-button-cat button img {
  width: 16px;
  height: 16px;
}

.document-export-button {
  color: #8B55DD;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.document-delete-button {
  color: rgba(226, 25, 25, 0.76);
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.document-cancel-button {
  color: var(--Text-h1, #18101C);
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.document-button-cat button:hover {
  background-color: #f0f0f0;
}


.cancel-bulk.document {
  display: flex !important;
  padding: 8px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 4px !important;
  border-radius: 28px;
  border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
  background: #ECECEC;
}
.rename-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.rename-modal {
  border-radius: 12px;
border: 1px solid rgba(0, 0, 0, 0.20);
background: #FFF;
box-shadow: 90px 417px 119px 0px rgba(0, 0, 0, 0.00), 58px 267px 109px 0px rgba(0, 0, 0, 0.01), 33px 150px 92px 0px rgba(0, 0, 0, 0.05), 14px 67px 68px 0px rgba(0, 0, 0, 0.09), 4px 17px 38px 0px rgba(0, 0, 0, 0.10);
 
  padding: 20px;
  
  width: 566px;
height: 277px;
}

.rename-modal h2 {
  color: var(--Text-h2, #171717);
font-family: Outfit;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 15px;
margin-left: 22px;
}

.rename-modal p{
  color: var(--Neutral_2, #52525B);
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 10px;
margin-left: 22px;
}
.rename-modal h3{
  color: var(--Neutral-200, #52525B);
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 24px;
margin-left: 22px;
}
.rename-modal input {
  width: 472px;
height: 52px;
flex-shrink: 0;
  padding: 8px;

  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.20);
  background: #FFF;
  box-shadow: 90px 417px 119px 0px rgba(0, 0, 0, 0.00), 58px 267px 109px 0px rgba(0, 0, 0, 0.01), 33px 150px 92px 0px rgba(0, 0, 0, 0.05), 14px 67px 68px 0px rgba(0, 0, 0, 0.09), 4px 17px 38px 0px rgba(0, 0, 0, 0.10);

  margin-bottom: 20px;

  margin-top: 10px;
  margin-left: 22px;
  color: var(--Neutral_2, #52525B);
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;

}

.rename-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.rename-modal-actions button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.rename-modal-actions button:first-child {
  display: inline-flex;
padding: 8px 36px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 6px;
border: 1px solid var(--Primary-500, #C95EBE);
color: var(--Primary-500, #C95EBE);
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: var(--Typography-Line-Height-300, 24px); 
letter-spacing: 0.14px;
margin-right: 30px;
}

.rename-modal-actions button:last-child {
  display: inline-flex;
padding: 8px 36px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 6px;
border: 1px solid var(--Primary-500, #C95EBE);
background: var(--Primary-500, #C95EBE);
color: var(--White, #F5F4F3);
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: var(--Typography-Line-Height-300, 24px);
letter-spacing: 0.14px;
margin-right: 30px;
}
.copy-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.new-folder-input{
  color: rgba(82, 82, 91, 0.60);
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-top: 20px;
}
.save-button-copy{
  display: flex;
width: 96px;
height: 32px;
padding: 10px 34px 10px 33px;
justify-content: center;
align-items: center;
border-radius: 8px;
background: var(--Primary-500, #C95EBE);
color: var(--White, #F5F4F3);
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left: 43px;

}
.new-folder-container{
  width: 246px;
height: 44px;
flex-shrink: 0;
border-radius: 12px;
border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
background: #FFF;
margin-left: 17px;
}
.create-new-folder-btn {
  margin-left: 21px;
  display: inline-flex;
  padding: 10px 286px 10px 16px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #C95EBE;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: background 0.3s ease;
}

.create-new-folder-btn:hover {
  background: #b14fae;
}

.folder-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.create-new-folder-btn span {
  color: #F5F4F3;
  font-family: Inter, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.copy-modal {
 
  padding: 20px;
 
  width: 526px;
height: 404px;
flex-shrink: 0;
border-radius: 12px;
border: 1px solid rgba(0, 0, 0, 0.20);
background: #FFF;
box-shadow: 90px 417px 119px 0px rgba(0, 0, 0, 0.00), 58px 267px 109px 0px rgba(0, 0, 0, 0.01), 33px 150px 92px 0px rgba(0, 0, 0, 0.05), 14px 67px 68px 0px rgba(0, 0, 0, 0.09), 4px 17px 38px 0px rgba(0, 0, 0, 0.10);
  
}

.copy-modal h2 {
  color: var(--Text-h2, #171717);
font-family: Outfit;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 12px;
margin-left: 20px;
}

.copy-modal p {
  color: var(--Neutral_2, #52525B);
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 15px;
margin-left: 20px;
}


.custom-select {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  width: 462px;
height: 52px;
flex-shrink: 0;
margin-left: 20px;
border-radius: 12px;
border: 1px solid rgba(0, 0, 0, 0.20);
}

.selected-option {
  flex-grow: 1;
  padding-right: 10px;
  color: #000;
}

.custom-dropdown-options {
  position: absolute;
  top: 110%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 10;
  width: 462px;
height: 146px;
flex-shrink: 0;
margin-left: 20px;
border-radius: 12px;
border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
background: #FFF;
}
.custom-dropdown-options::-webkit-scrollbar {
  display: none;
}

.custom-dropdown-option {
  padding-top: 5px;
  padding-left: 10px;
  cursor: pointer;
  padding-left: 10px;
  padding-bottom: -10px;
  margin-left: 14px;
  margin-top: 5px;
 
}

.custom-dropdown-option:hover {
  background: var(--Background-main, #FEF9F7);
  width: 411px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
  background: var(--Background-main, #FEF9F7);
}

.copy-modal h3{
  color: var(--Neutral-200, #52525B);
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 25px;
margin-left: 20px;
}
.copy-modal-select {
  width: 100%;
  padding: 8px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.20);
  margin-top: 10px;
  width: 450px;
height: 52px;
flex-shrink: 0;
margin-left: 20px;
}
.copy-modal-select {
  appearance: none; 
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
}
.copy-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  color: var(--Neutral_2, #52525B);
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.select-icon {
  position: absolute; 
  top: 39%; 
  left: 93%; 
  transform: translateX(-50%); 
  width: 20px;
  height: 20px; 
}

.select-icon img {
  width: 100%; 
  height: auto;
}
.select-container {
  position: relative;
  width: 100%;
}


.copy-modal-actions button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.custom-dropdown-folder{
  display: flex;
}
.dropdown-list {
  position: absolute;
  top: 385px; 
  width: 29.4%;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  left: 546px;
  height: 146px;
}

.dropdown-item {
  margin-left: 12px;
  margin-top: 8px;
  padding: 8px;
  
  cursor: pointer;
  margin-bottom: 10px;
  color: var(--Neutral_2, #52525B);
font-family: Outfit;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
  
}
.error-message-folderCopy{
  margin-top: 30px;
}
.dropdown-item:hover {
  background-color: #f7f7f7;
  width: 411px;
height: 38px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
background: var(--Background-main, #FEF9F7);
}

.dropdown-item.selected {
  background: #fef3f2; 
  border-radius: 8px;
}
.selected-folder{
  width: 452px;
  display: flex;
  margin-top: 10px;
  padding-left: 30px;
  padding-top: 12px;
height: 52px;
flex-shrink: 0;
border-radius: 12px;
margin-left: 19px;
color: var(--Neutral_2, #52525B);
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
border: 1px solid rgba(0, 0, 0, 0.20);
}

.dropdown-icon-copyfolder{
  width: 24px;
height: 24px;
flex-shrink: 0;
margin-left: 280px;
}
.copy-modal-actions button:first-child {
  display: inline-flex;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
border: 1px solid var(--Primary-500, #C95EBE);
color: var(--Primary-500, #C95EBE);
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: var(--Typography-Line-Height-300, 24px); 
letter-spacing: 0.14px;
margin-top: 70px;
}
.cancel-button-copy{
  height: 32px;
 
  display: inline-flex;
padding: 10px 28px 10px 27px;
justify-content: center;
align-items: center;
border-radius: 8px;
border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
color: var(--text_non_active, #73607B);
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left: 10px;
}

.copy-modal-actions button:last-child {
  display: inline-flex;
padding: 19px 36px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 6px;
border: 1px solid var(--Primary-500, #C95EBE);
background: var(--Primary-500, #C95EBE);
color: var(--White, #F5F4F3);
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: var(--Typography-Line-Height-300, 24px);
letter-spacing: 0.14px;
margin-top: 70px;
margin-right: 20px;
}

.success-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.folder-uploaded-success{
  color: var(--Text-h2, #171717);
font-family: Outfit;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.success-popup-box {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 526px;
height: 246px;
flex-shrink: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.stacked-icons-container {
  position: relative;
  width: 100px; 
  height: 100px; 
  margin: 0 auto; 
}

.stacked-icons-container .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stacked-icons-container .tick-icon {
  z-index: 3;
  width: 36px;
height: 36px;
flex-shrink: 0; 
}

.stacked-icons-container .circle-icon {
  z-index: 2; 
  width: 59px;
height: 59px;
flex-shrink: 0;
}

.stacked-icons-container .copied-icon {
  z-index: 1; 
  width: 87px;
height: 87px;
flex-shrink: 0;
}
.folder-folderName{
  color: var(--Neutral-150, #71717A);
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.success-popup-icon img {
  width: 87px;
  height: 87px;
  flex-shrink: 0;
  margin-left: 200px;
}

.success-popup-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.success-popup-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.copy-folder-view{
  display: flex;
padding: 8px 36px;
justify-content: center;
align-items: center;
gap: 18px;
border-radius: 6px;
border: 1px solid var(--Primary-500, #C95EBE);
color: var(--Primary-500, #C95EBE);
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: var(--Typography-Line-Height-300, 24px); 
letter-spacing: 0.14px;
margin-top: 10px;
margin-left: 4rem;

}
.copy-folder-done{
  display: flex;
padding: 8px 60px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 6px;
border: 1px solid var(--Primary-500, #C95EBE);
background: var(--Primary-500, #C95EBE);
margin-left: 32px;
height: 45px;
margin-top: 12px;

}
.copy-folder-last{
  display: flex;
}
.success-popup-actions button:first-child {
  background: white;
  border: 2px solid #b793ed;
  color: #b793ed;
}

.success-popup-actions button:last-child {
  background: #b793ed;
  color: white;
}
